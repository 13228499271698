import React, { Component } from "react";
import { Translation } from "react-i18next";
import './index.css'

class ProfileProgressBar extends Component {
  render() {
    const { score } = this.props
    return (
      <Translation>
        {t =>
          <>
            <div className="NE_progress_profile_infos">
              <div className="profile_progress">
                <div className="progress_bar">
                  <div className="progress_level" style={{ width: `${score * 0.33}%` }}>
                    <div className="tooltip_container">
                      {
                        score < 100 &&
                        <div className="tooltip">{t("participant.user-metrics.level1")}</div>
                      }
                      {
                        score >= 100 &&
                        <div className="tooltip">{t("participant.user-metrics.level2")}</div>
                      }
                      {
                        score >= 200 &&
                        <div className="tooltip">{t("participant.user-metrics.level3")}</div>
                      }
                      {
                        score >= 300 &&
                        <div className="tooltip">{t("participant.user-metrics.level4")}</div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </Translation>
    )
  }
}
export default ProfileProgressBar;
