import React,{Component} from "react";
import Create_element from "../create_element";
import style from  "./scss.module.scss"

const CreateElementsModal = ({
                                 isOpen,
                                 onCancel ,
                                 games_list = [],
                                 onSave=()=>null
                             })=>{


        let class_name = "configure_course_modal_wrapper ";
        class_name += isOpen ? "configure_course_modal_open" : "";

        return(
            <div className={`${class_name} ${style.configure_course_modal_wrapper}`} onClick={(e) => {
                if(e.target.className === class_name){
                    onCancel();
                }
            }}>

                <div className={`NE_body ${style.NE_body}`}>
                    <div className="NE_text">
                        <h3 className="NE_text_body">Create Element</h3>
                        <h3 className={`NE_text_body _close_button ${style._close_button}`} onClick={()=>{
                            onCancel();
                        }} >x</h3>
                    </div>
                    <div className="NE_body_center" />
                    <div className="NE_search_bar">
                        <div className="NE_icon_input NE_icon_input_success NE_icon_input_error">
                            <div className="input_wrapper">
                                <Create_element onCancel={onCancel} onSave={onSave}/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );

}

export  default CreateElementsModal;
