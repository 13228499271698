import React,{Component} from "react";
import {Trans, Translation} from "react-i18next";
import './index.css';

class ElementProgressOverview extends Component{

    render() {
        const {element} = this.props;

        const element_percentage = element?.completed_participants !== undefined ? (element?.completed_participants * 100) : (element?.correct_answers * 100);


        return <Translation>
            {t => {
                let average_progress_title = "UNDEFINED";

                switch (element?.type){
                    case "BUSINESS_GAME":
                        average_progress_title = t("moderator.training-sessions.statics.session-dashboards.dashboard-global.business_game_title");
                        break;

                    case "DOCUMENT":
                        average_progress_title = t("moderator.training-sessions.statics.session-dashboards.dashboard-global.document_title");
                        break;

                    case "NOTION":
                        average_progress_title = t("moderator.training-sessions.statics.session-dashboards.dashboard-global.notion_title");
                        break;

                    case "EVALUATION":
                        average_progress_title = t("moderator.training-sessions.statics.session-dashboards.dashboard-global.average_evaluation_score_title");
                        break;

                    case "QUIZ":
                        average_progress_title = t("moderator.training-sessions.statics.session-dashboards.dashboard-global.average_quiz_score_title");
                        break;

                    default:
                        break;
                }

                return (
                    <div className="NE_element_progress_overview">
                        {element && <div className="average_progress">
                            <div className="progress_infos">
                                <p className="title">{average_progress_title}</p>
                                <p className="percentage">{element_percentage}%</p>
                            </div>
                            <div className="progress_bar">
                                <div className="progress_level" style={{width: `${element_percentage}%`}}/>
                            </div>
                        </div>}
                        {(element?.type === "QUIZ" || element?.type === "EVALUATION") && <>
                            {element?.questions.map(question => {
                                const question_percentage = question.correct_answers * 100;

                                return (
                                    <div key={question.id} className="question_progress">
                                        <p className="question_number">{question.title}</p>
                                        <div className="progress_bar">
                                            <div className="progress_level" style={{width: `${question_percentage}%`}}/>
                                        </div>
                                        <p className="percentage">{question_percentage}%</p>
                                    </div>
                                );
                            })}
                        </>}
                    </div>
                );
            }}
        </Translation>
    }
}
export default ElementProgressOverview;