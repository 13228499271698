import { getSecureAxiosInstance } from "./axios-instance";

export const loadAllCourses = () => {
  return getSecureAxiosInstance().get("moderator/courses");
};

export const loadAllCommentService = () => {
  return getSecureAxiosInstance().get("moderator/last-comments");
};

export const loadElementsCourseService = course_id => {
  return getSecureAxiosInstance().get(
    `moderator/course-cloneable-elements?course_id=${course_id}`
  );
};

export const cloneElementsCourseService = course => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}moderator/clone-elements`,
    { ...course }
  );
};

export const loadAllCategories = () => {
  return getSecureAxiosInstance().get("moderator/course-categories");
};

export const loadAllGames = () => {
  return getSecureAxiosInstance().get("moderator/games");
};

export const addCourseService = (image, title, description, category) => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}moderator/add-course`,
    {
      title: title,
      description: description,
      category_id: category,
      image_path: image
    }
  );
};


// {
//     "category_id": 2,
//     "title": "Mecanique",
//     "description": "Mecanique des solides",
//     "image_path": "https://physique.ensc-rennes.fr/images/tp_pendules/texte/fig7.png"
// }
export const editCourseService = (id,image, title, description, category) => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}moderator/edit-course`,
    {
      id,
      title: title,
      description: description,
      category_id: category,
      image_path: image
    }
  );
};

export const addModuleService = (
  course_id,
  title,
  description,
  element_order
) => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}moderator/add-module`,
    {
      title: title,
      description: description,
      element_order: element_order,
      course_id: course_id
    }
  );
};


export const editOrdersModuleService = (
    course_id,
    modules,
    evaluations,
    games,
    documents
) => {
    return getSecureAxiosInstance().post(
        `${process.env.REACT_APP_BACKEND_URL}moderator/edit-orders`,
        {
            course_id,
            modules,
            evaluations,
            games,
            documents
        }
    );
};

export const editModuleService = (
    id,
    title,
    description,
    element_order,
    published
) => {
    return getSecureAxiosInstance().post(
        `${process.env.REACT_APP_BACKEND_URL}moderator/edit-module`,
        {
            id,
            title,
            description,
            element_order,
            published
        }
    );
};

export const editEvaluationService = (
    id,
    title,
    description,
    element_order,
    published
) => {
    return getSecureAxiosInstance().post(
        `${process.env.REACT_APP_BACKEND_URL}moderator/edit-evaluation`,
        {
            id,
            title,
            description,
            element_order,
            published
        }
    );
};

export const loadAllSessions = course_id => {
  return getSecureAxiosInstance().get(
    `moderator/course-training-sessions?course_id=${course_id}`
  );
};

export const createSession = session_data => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}moderator/add-training-session`,
    {
      course_id: session_data.course_id,
      title: session_data.title,
      start_date: session_data.start_date,
      end_date: session_data.end_date,
      has_satisfaction_study: session_data.has_satisfaction_study ? 1 : 0
    }
  );
};

export const updateSession = session_data => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}moderator/edit-training-session`,
    {
      id: session_data.id,
      title: session_data.title,
      start_date: session_data.start_date,
      end_date: session_data.end_date,
      has_satisfaction_study: session_data.has_satisfaction_study
    }
  );
};

export const deleteSession = session_id => {
  return getSecureAxiosInstance().delete(
    `${process.env.REACT_APP_BACKEND_URL}moderator/delete-training-session?id=${session_id}`,
    {
      method: "DELETE",
      data: {
        id: session_id
      }
    }
  );
};

export const loadSelectedSessionKpis = session_id => {
  return getSecureAxiosInstance().get(
    `${process.env.REACT_APP_BACKEND_URL}moderator/training-session-dashboards?training_session_id=${session_id}`
  );
};

export const changeStudentActivation = (session_id, student_id, is_active) => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}moderator/edit-participant-activeness`,
    {
      training_session_id: session_id,
      participant_id: student_id,
      is_active: is_active ? 1 : 0
    }
  );
};

export const createComment = (discussion_id, parent_id, content) => {
  let data = {
    discussion_id: discussion_id,
    content: content
  };

  if (parent_id) {
    data.parent_message_id = parent_id;
  }

  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}moderator/save-comment`,
    data
  );
};

export const addDocumentService = (
  course_id,
  title,
  media_path,
  element_order,
  attached_documents,
  description
) => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}moderator/add-documents`,
    {
      course_id: course_id,
      title: title,
      media_path: "http://cdn.website.com/path/to/media",
      element_order: element_order,
      attached_documents: attached_documents,
      description
    }
  );
};


// {
//     "element_order": 13373,
//     "id": 27,
//     "media_path": "http://cdn.website.com/path/to/media_edited",
//     "title": "doc4 edited"
// }
export const editDocumentService = (
    id,
    title,
    media_path,
    element_order,
    description,
    published
) => {
    return getSecureAxiosInstance().post(
        `${process.env.REACT_APP_BACKEND_URL}moderator/edit-documents`,
        {
            id,
            title,
            media_path: "http://cdn.website.com/path/to/media",
             element_order,
            description,
            published
        }
    );
};

export const addDocumentsElement = (
  course_id,
  title,
  element_order,
  attached_documents,
  description,
) => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}moderator/add-documents`,
    {
      course_id: course_id,
      title: title,
      element_order: element_order,
      attached_documents: attached_documents,
        description
    }
  );
};

export const loadCourseContent = course_id => {
  return getSecureAxiosInstance().get(
    `${process.env.REACT_APP_BACKEND_URL}moderator/course?course_id=${course_id}`
  );
};

export const addNotionService = (
  module_id,
  title,
  media_path,
  element_order,
  content,
  attached_documents
) => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}moderator/add-notion`,
    {
      module_id: module_id,
      title: title,
      media_path: media_path,
      element_order: element_order,
      content: content,
      attached_documents: attached_documents
    }
  );
};

export const addQuizService = (module_id, title, element_order, questions) => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}moderator/add-quiz`,
    {
      module_id: module_id,
      title: title,
      questions: questions,
      element_order: element_order
    }
  );
};
export const addEvaluationService = (
  course_id,
  title,
  element_order,
  questions
) => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}moderator/add-evaluation`,
    {
      course_id: course_id,
      title: title,
      questions: questions,
      element_order: element_order
    }
  );
};
export const addBusinessGameService = ({
  course_id,
  game_id,
  client_affectation_id,
  element_order
}) => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}moderator/add-game`,
    {
      course_id: course_id,
      client_affectation_id: client_affectation_id,
      game_id: game_id,
      element_order: element_order
    }
  );
};

export const editBusinessGameService = (
                                          id,
                                          element_order,
                                          published
                                       ) => {
    return getSecureAxiosInstance().post(
        `${process.env.REACT_APP_BACKEND_URL}moderator/edit-game`,
        {
            id,
            element_order,
            published
        }
    );
};

export const deleteNotionService = id => {
  return getSecureAxiosInstance().delete(
    `${process.env.REACT_APP_BACKEND_URL}moderator/delete-notion`,
    {
      data: { id: id }
    }
  );
};

export const deleteCourseService = id => {
  return getSecureAxiosInstance().delete(
    `${process.env.REACT_APP_BACKEND_URL}moderator/delete-course`,
    {
      data: { id: id }
    }
  );
};

export const editNotionService = notion => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}moderator/edit-notion`,
    notion
  );
};

export const deleteModuleService = id => {
  return getSecureAxiosInstance().delete(
    `${process.env.REACT_APP_BACKEND_URL}moderator/delete-module`,
    {
      data: { id: id }
    }
  );
};
export const deleteGameService = id => {
  return getSecureAxiosInstance().delete(
    `${process.env.REACT_APP_BACKEND_URL}moderator/delete-game`,
    {
      data: { id: id }
    }
  );
};
export const deleteDocumentsService = id => {
  return getSecureAxiosInstance().delete(
    `${process.env.REACT_APP_BACKEND_URL}moderator/delete-documents`,
    {
      data: { id: id }
    }
  );
};
export const deleteEvaluationService = id => {
  return getSecureAxiosInstance().delete(
    `${process.env.REACT_APP_BACKEND_URL}moderator/delete-evaluation`,
    {
      data: { id: id }
    }
  );
};
export const deleteQuizService = id => {
  return getSecureAxiosInstance().delete(
    `${process.env.REACT_APP_BACKEND_URL}moderator/delete-quiz`,
    {
      data: { id: id }
    }
  );
};
export const deleteQuestionService = id => {
  return getSecureAxiosInstance().delete(
    `${process.env.REACT_APP_BACKEND_URL}moderator/delete-question`,
    {
      data: { id: id }
    }
  );
};
export const deleteQuestionOptionService = id => {
  return getSecureAxiosInstance().delete(
    `${process.env.REACT_APP_BACKEND_URL}moderator/delete-question-option`,
    {
      data: { id: id }
    }
  );
};
export const deleteAttachedDocumentsService = id => {
  return getSecureAxiosInstance().delete(
    `${process.env.REACT_APP_BACKEND_URL}moderator/delete-attached-document`,
    {
      data: { id: id }
    }
  );
};

export const addAttachedDocumentsService = (documents_id, title, file_path) => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}moderator/add-attached-document`,
    {
      documents_id: documents_id,
      title: title,
      file_path: file_path
    }
  );
};

export const addAttachedDocumentsNotionService = (notion_id, title, file_path) => {
    return getSecureAxiosInstance().post(
        `${process.env.REACT_APP_BACKEND_URL}moderator/add-attached-document`,
        {
            notion_id,
            title: title,
            file_path: file_path
        }
    );
};

export const addQuestionService = data => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}moderator/add-question`,
    data
  );
};

export const addQuestionOptionService = (
  content,
  is_correct,
  question_id,
  element_order
) => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}moderator/add-question-option`,
    {
      content,
      is_correct,
      question_id,
      element_order
    }
  );
};

export const editQuestionService = (element_order, title, id,justification) => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}moderator/edit-question`,
    {
      element_order,
      title,
      id,
      justification
    }
  );
};

export const editQuestionOptionService = (
  content,
  is_correct,
  element_order,
  id
) => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}moderator/edit-question-option`,
    {
      content,
      is_correct,
      element_order,
      id
    }
  );
};

export const clientExpired = data => {
    return getSecureAxiosInstance().get("/moderator/client-expired");
};
