import Joi from "@hapi/joi";

// export default Joi.object({
//     full_name: Joi.string().alphanum().min(3).required(),
//     type: Joi.string().min(3).required(),
//     phone: Joi.string().pattern(new RegExp("^\+[0-9]{1,3} [0-9]{4,}$")).required(),
//     email: Joi.string().email().required(),
//     address: Joi.string().min(3).required(),
// });

export const image_size_schema = Joi.number()
  .integer()
  .max(5000000);
export const full_name_schema = Joi.string()
  .min(3)
  .required();
export const title_schema = Joi.string()
  .min(5)
  .required();
export const description_schema = Joi.string()
  .min(5)
  .required();
export const language_schema = Joi.string()
  .min(1)
  .required();
export const type_schema = Joi.string()
  .min(3)
  .required();
export const phone_schema = Joi.string()
  // .pattern(new RegExp("^\\+[0-9]{1,3} [0-9]{4,}$"))
  .required();
export const email_schema = Joi.string()
  .email({ tlds: { allow: false } })
  .required()
    .messages({
        'string.email': 'must be a valid email',
        'string.empty': `email cannot be an empty field`,
        'any.required': `email is a required field`
    })

export const address_schema = Joi.string()
  .min(3)
  .required();
export const date_schema = Joi.date().required();
export const password_schema = Joi.string()
  .alphanum()
  .min(5)
  .required()
  .messages({
        'string.empty': `password cannot be an empty field`,
        'string.min': `password should have a minimum length of {#limit}`,
        'any.required': `password is a required field`
  })
export const session_title_schema = Joi.string()
  .min(3)
  .required()
    .messages({
        'string.empty': `Nom session cannot be an empty field`,
        'string.min': `Nom session should have a minimum length of {#limit}`,
        'any.required': `Nom session is a required field`
    })
