import { all, call, delay, fork, put, takeLatest } from "redux-saga/effects";
import {
  addAttachedDocumentSuccess,
  deleteAttachedDocumentFailed,
  deleteAttachedDocumentsSuccess,
  editEvaluationSuccess,
  editNotionSuccess,
  editQuizSuccess,
  isLoading,
  deleteModuleFailed,
  deleteModuleSuccess,
  deleteDocumentsFailed,
  deleteDocumentsSuccess,
  deleteEvaluationFailed,
  deleteEvaluationSuccess,
  deleteQuizFailed,
  deleteQuizSuccess,
  deleteNotionFailed,
  deleteNotionSuccess, addElementCourseSuccess, addElementCourseFailed, loadData
} from "./actions";
import {
  ADD_ATTACHED_DOCUMENT,
  DELETE_ATTACHED_DOCUMENTS,
  EDIT_EVALUATION,
  EDIT_NOTION,
  EDIT_QUIZ,
  DELETE_MODULE,
  DELETE_DOCUMENTS,
  DELETE_EVALUATION,
  DELETE_QUIZ,
  DELETE_NOTION, ADD_ELEMENT_COURSE
} from "./action-types";
import {
  addAttachedDocumentsNotionService,
  addAttachedDocumentsService, addBusinessGameService, addDocumentService, addEvaluationService, addModuleService,
  addNotionService,
  addQuestionOptionService,
  addQuestionService, addQuizService,
  deleteAttachedDocumentsService, deleteCourseService,
  deleteDocumentsService,
  deleteEvaluationService,
  deleteModuleService,
  deleteNotionService,
  deleteQuestionOptionService,
  deleteQuestionService,
  deleteQuizService,
  editNotionService,
  editQuestionOptionService,
  editQuestionService
} from "../../../services/moderator.service";
import { uploadDocument } from "../../../services/uploads.service";
import { loadCourseContent } from "../configure-course/actions-creators";

function* deleteAttachedDocumentsWorker({ payload }) {
  try {
    const user = yield call(deleteAttachedDocumentsService, payload.id);
    yield put(deleteAttachedDocumentsSuccess(payload));
  } catch (e) {
    yield put(deleteAttachedDocumentFailed(e));
  }
}

function* addAttachedDocumentsWorker({ payload }) {
  try {
    const { files, doc } = payload;

    const uploaded_documents = [];

    if (files.length === 0) throw new Error();
    for (let i = 0; i < files.length; i++) {
      const call_result = yield uploadDocument(files.item(i));

      if (call_result.data.status === "success") {
        uploaded_documents.push({
          title: files.item(i).name,
          file_path: call_result.data.message
        });
        yield addAttachedDocumentsService(
          doc.id,
          files.item(i).name,
          call_result.data.message
        );
      }
    }

    yield put(addAttachedDocumentSuccess(uploaded_documents, doc.id));
    // yield put(deleteAttachedDocumentsSuccess(payload));
  } catch (e) {
    yield put(deleteAttachedDocumentFailed(e));
  }
}

function* addEvaluationWorker({ payload }) {
  try {
    // console.log("---payload---",payload);
    const {
      list_add_question,
      list_add_question_option,
      list_edit_question,
      list_edit_question_option,
      list_delete_question_option,
      list_delete_question
    } = payload;

    // const image_upload_result = yield uploadDocument(payload);
    const resp_list_add_question = [];
    const resp_list_add_question_option = [];
    const resp_list_edit_question = [];
    const resp_list_edit_question_option = [];

    if (list_edit_question_option.length > 0) {
      for (let elem of list_edit_question_option) {
        const { content, is_correct, element_order, id } = elem;
        const res = yield editQuestionOptionService(
          content,
          is_correct,
          element_order,
          id
        );
        resp_list_edit_question_option.push(res.data);
      }

      //
    }
    if (list_delete_question_option.length > 0) {
      for (let elem of list_delete_question_option) {
        const { content, is_correct, element_order, id } = elem;
        // console.log("------elem------",elem)
        const res = yield deleteQuestionOptionService(id);
      }

      //
    }

    if (list_add_question.length > 0) {
      for (let elem of list_add_question) {
        const res = yield addQuestionService(elem);
        resp_list_add_question.push(res.data);
      }
    }
    if (list_delete_question.length > 0) {
      for (let elem of list_delete_question) {
        const res = yield deleteQuestionService(elem.id);
      }
    }
    if (list_edit_question.length > 0) {
      for (let elem of list_edit_question) {
        const { element_order, id, title , justification} = elem;
        const res = yield editQuestionService(element_order, title, id,justification);

        resp_list_edit_question.push(res.data);
      }
    }

    if (list_add_question_option.length > 0) {
      for (let elem of list_add_question_option) {
        const { content, is_correct, question_id, element_order } = elem;
        const res = yield addQuestionOptionService(
          content,
          is_correct,
          question_id,
          element_order
        );

        resp_list_add_question_option.push({ ...res.data, ...elem });
      }
    }

    yield put(
      editEvaluationSuccess({
        resp_list_add_question,
        resp_list_add_question_option,
        resp_list_edit_question,
        list_edit_question_option,
        list_delete_question_option,
        list_delete_question,
        list_edit_question
      })
    );
  } catch (e) {

    yield put(deleteAttachedDocumentFailed(e));
  }
}

function* editQuizWorker({ payload }) {
  try {
    yield put(isLoading());
    let {
      list_add_question,
      list_add_question_option,
      list_edit_question,
      list_edit_question_option,
      list_delete_question_option,
      list_delete_question,
      module
    } = payload;

    // const image_upload_result = yield uploadDocument(payload);
    const resp_list_add_question = [];
    const resp_list_add_question_option = [];
    const resp_list_edit_question = [];
    const resp_list_edit_question_option = [];

    if (list_edit_question_option.length > 0) {
      for (let elem of list_edit_question_option) {
        const { content, is_correct, element_order, id } = elem;
        const res = yield editQuestionOptionService(
          content,
          is_correct,
          element_order,
          id
        );
        resp_list_edit_question_option.push(res.data);
      }

      //
    }
    if (list_delete_question_option.length > 0) {
      for (let elem of list_delete_question_option) {
        const { content, is_correct, element_order, id } = elem;
        // console.log("------elem------",elem)
        const res = yield deleteQuestionOptionService(id);
      }

      //
    }

    if (list_add_question.length > 0) {
        const isNewQuiz =   list_add_question.some( elm => elm.quiz_id === undefined );


      if (isNewQuiz){
        const res = yield addQuizService(module.id, "title", module.items.length , []);

        // console.log("------res------",res)
        // console.log("------list_add_question------",list_add_question)
        list_add_question = list_add_question.map((elem)=>{
          return {...elem,quiz_id:res.data.id}
        })
      }

      for (let elem of list_add_question) {
        const res = yield addQuestionService(elem);
        resp_list_add_question.push(res.data);
      }
    }
    if (list_delete_question.length > 0) {
      for (let elem of list_delete_question) {
        const res = yield deleteQuestionService(elem.id);
      }
    }
    if (list_edit_question.length > 0) {
      for (let elem of list_edit_question) {
        const { element_order, id, title , justification } = elem;
        const res = yield editQuestionService(element_order, title, id,justification);

        resp_list_edit_question.push(res.data);
      }
    }

    if (list_add_question_option.length > 0) {
      for (let elem of list_add_question_option) {
        const { content, is_correct, question_id, element_order } = elem;
        const res = yield addQuestionOptionService(
          content,
          is_correct,
          question_id,
          element_order
        );

        resp_list_add_question_option.push({ ...res.data, ...elem });
      }
    }

    yield put(editQuizSuccess(false));
    yield put(loadCourseContent(module.course_id));
    yield put(isLoading(false));

    // yield put(editEvaluationSuccess({
    //     resp_list_add_question,
    //     resp_list_add_question_option,
    //     resp_list_edit_question,
    //     list_edit_question_option,
    //     list_delete_question_option,
    //     list_delete_question,
    //     list_edit_question
    // }))
  } catch (e) {

    // yield put(deleteAttachedDocumentFailed(e));
  }
}

function* editNotionWorker({ payload }) {
  try {
    yield put(isLoading());
    const uploaded_documents = [];

    if (!payload.id) {

      const docs = payload.doc;

      for (let i = 0; i < docs.length; i++) {
        
        const call_result = yield uploadDocument(docs[i]);

        if (call_result.data.status === "success") {
          uploaded_documents.push({
            title: docs[i].name,
            file_path: call_result.data.message
          });
        }
      }

      yield addNotionService(
        payload.module_id,
        payload.title,
        payload.media_path,
        payload.element_order,
        payload.content,
        uploaded_documents
      );

      yield put(loadCourseContent(payload.current_course_id));
    } else {
      const docs = payload.doc;

      for (let i = 0; i < docs.length; i++) {
        const call_result = yield uploadDocument(docs[i]);
        const res = yield addAttachedDocumentsNotionService( payload.id,docs[i].name,call_result.data.message);
        uploaded_documents.push(res.data);
      }

      // documents_id(pin):null
      // file_path(pin):"https://www.nowedge.io/lms-php-api/staging/documents/512153-dummy.pdf"
      // id(pin):307
      // notion_id(pin):353
      // title(pin):"dummy.pdf"

      const res = yield editNotionService(payload);
      const notion = {...res.data,attached_documents: uploaded_documents.length > 0 ? uploaded_documents :null}
      yield put(editNotionSuccess(notion));
    }

    yield put(isLoading(false));
  } catch (e) {
    console.log("----editNotionWorker-----", e);
  }
}

function* deleteModuleWorker({ payload: { id } }) {
  try {
    yield deleteModuleService(id);

    yield put(deleteModuleSuccess(id));
  } catch (e) {

    yield put(deleteModuleFailed("error"));
  }
}

function* deleteDocumentsWorker({ payload: { id, _id } }) {
  try {
    yield deleteDocumentsService(id);

    yield put(deleteDocumentsSuccess(id));
  } catch (e) {
    yield put(deleteDocumentsFailed("error"));
  }
}

function* deleteEvaluationWorker({ payload: { id, callback = () => {} } }) {
  try {
    yield deleteEvaluationService(id);

    yield put(deleteEvaluationSuccess(id));

    callback();
  } catch (e) {

    yield put(deleteEvaluationFailed("error"));
  }
}

function* deleteQuizWorker({
  payload: { module_id, id, callback = () => {} }
}) {
  try {
    yield deleteQuizService(id);

    yield put(deleteQuizSuccess(module_id, id));

    callback();
  } catch (e) {
    yield put(deleteQuizFailed("error"));
  }
}

function* deleteNotionWorker({
  payload: { module_id, id, course_id, callback = () => {} }
}) {
  try {
    yield deleteNotionService(id);

    yield put(loadCourseContent(course_id));

    yield put(deleteNotionSuccess(module_id, id));

    callback();
  } catch (e) {

    yield put(deleteNotionFailed("error"));
  }
}

function* addElementCourseWorker({
  payload: { data }
}) {
  try {

    const {course_id, title, description="", element_order, attached_documents = [] , questions=[] , client_affectation_id , game_id} = data;


    if (data.type === "MODULE"){
      yield addModuleService(course_id,
          title,
          description,
          element_order);
    }
    else if  (data.type === "DOCUMENTS"){
      yield addDocumentService(course_id,
          title,
          "",
          element_order,
          attached_documents,
          ""
          );
    }else if  (data.type === "EVALUATION"){
      yield addEvaluationService(course_id,
          title,
          element_order,
          questions);
    }
    else if (data.type === "BUSINESS_GAME"){
      yield addBusinessGameService({
        course_id,
        game_id,
        client_affectation_id,
        element_order
      });
    }

    yield put(loadCourseContent(course_id));

    yield put(addElementCourseSuccess());

  } catch (e) {

    yield put(deleteNotionFailed("error"));
    yield put(addElementCourseFailed(e));

  }
}

export function* watchDeleteAttachedDocuments() {
  yield takeLatest(DELETE_ATTACHED_DOCUMENTS, deleteAttachedDocumentsWorker);
}

export function* watchAddAttachedDocuments() {
  yield takeLatest(ADD_ATTACHED_DOCUMENT, addAttachedDocumentsWorker);
}

export function* watchEvaluation() {
  yield takeLatest(EDIT_EVALUATION, addEvaluationWorker);
}

export function* watchEditNotion() {
  yield takeLatest(EDIT_NOTION, editNotionWorker);
}

export function* watchEditQuiz() {
  yield takeLatest(EDIT_QUIZ, editQuizWorker);
}

export function* watchDeleteModule() {
  yield takeLatest(DELETE_MODULE, deleteModuleWorker);
}

export function* watchDeleteDocuments() {
  yield takeLatest(DELETE_DOCUMENTS, deleteDocumentsWorker);
}
export function* watchDeleteEvaluation() {
  yield takeLatest(DELETE_EVALUATION, deleteEvaluationWorker);
}

export function* watchDeleteQuiz() {
  yield takeLatest(DELETE_QUIZ, deleteQuizWorker);
}

export function* watchDeleteNotion() {
  yield takeLatest(DELETE_NOTION, deleteNotionWorker);
}
export function* watchAddElementCourse() {
  yield takeLatest(ADD_ELEMENT_COURSE, addElementCourseWorker);
}

export default function* allSagas() {
  yield all([
    fork(watchDeleteAttachedDocuments),
    fork(watchAddAttachedDocuments),
    fork(watchEvaluation),
    fork(watchEditNotion),
    fork(watchEditQuiz),
    fork(watchDeleteModule),
    fork(watchDeleteDocuments),
    fork(watchDeleteEvaluation),
    fork(watchDeleteQuiz),
    fork(watchDeleteNotion),
    fork(watchAddElementCourse)
  ]);
}
