import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import './index.css'
import icon from './assets/icon.svg';
import iconNotion from './assets/file-remove.svg';
import iconAddFile from './assets/add-file.svg';
import add from "../../../../../components/ToggleBarButton/assets/add.svg";
import QuizComponent from "../quiz-component";
import RichEditor from "../../../configure-course/components/editor";
import * as PropTypes from "prop-types";
import {uid} from "uid";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {
    addNotion, editItemCourse, editModule
} from "../../../../../redux/moderator/configure-course/actions-creators";
import {
    editNotion,
    deleteModule,
    deleteNotion,
    deleteAttachedDocuments
} from "../../../../../redux/moderator/edit-course/actions";
import DeleteElementsModal from "../../../configure-course/components/delete-elements-modal";
import imageCloud from "../../../../../components/DocumentsUploader/assets/icon-upload-cloud.svg";
import Switch from "../../../../../components/Switch";
import PublishedElement from "../PublishedElement/PublishedElement";
import arrowUp from "../DocumentsCard/assets/arrow-up.svg";
import arrowDown from "../DocumentsCard/assets/arrow-down.svg";
import useMoveElementCourse from "../../../../../../utils/moveElementCourse";
// import DeleteElementsModal from "../delete-elements-modal";

export const ModuleItemContext = createContext({
    items: [],
    addItem: () => {
    },
    deleteItem: () => {
    },
    editItem: () => {
    },
    changeQuestionOption: () => {
    },
    loadData:()=>{

    },
    listDeleteQuestionOption:[]
});

const initialState = [

    // {
    //     text: "option",
    //     question_option:true,
    //     type:"quiz",
    //     _id: uid()
    // }
];

const ModuleItemsProvider = (props) => {

    const [items, setItems] = useState(initialState);

    const addItem = (item) => setItems((items) => [...items, item]);
    const deleteItem = (id) =>
        setItems((items) => items.filter((item) => item._id !== id));

    const editItem = (elem) => {
        setItems((items) => items.map((item) => {
            if (item._id === elem._id) return {...item, ...elem};
            return item;
        }));
    };

    const loadData = (list)=>{
        setItems(list)
    }


    const value = {
        items,
        addItem,
        deleteItem,
        editItem,
        loadData,
        listDeleteQuestionOption:[]
    };

    return (
        <ModuleItemContext.Provider value={value}>{props.children}</ModuleItemContext.Provider>
    );
};


function NotionFormComponent({elem, module, index , current_course}) {
    const {register, control, watch, formState: {errors}, handleSubmit , setValue} = useForm({});

    const [isCollapse, setIsCollapse] = useState(false);
    const [showButtons, setShowButtons] = useState(true);
    const {items, editItem, deleteItem} = useContext(ModuleItemContext);
    const [is_open_delete, setIsOpenDelete] = useState(false);
    const [attached_documents, setAttachedDocuments] = useState([]);

    const dispatch = useDispatch();

    React.useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (value.doc.length > 0 && name === "doc"){
                setAttachedDocuments((list)=>[...list,...value.doc]);
            }
            editItem({_id: elem._id, ...value});
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        setValue("title",elem.title)
        setValue("media",elem.media_path || "")
        setValue("description",elem.content)

        if (elem.attached_documents?.length > 0 && attached_documents.length === 0) {

            setAttachedDocuments(()=> ([...elem.attached_documents]));
        }

    }, []);


    useEffect(()=>{
        console.log("--elem.attached_documents?.length--",elem.attached_documents?.length);
        if(Array.isArray(elem.attached_documents))
        setAttachedDocuments(()=> ([...elem.attached_documents,...attached_documents.filter(elem=> !elem.id)]));
    },[elem.attached_documents?.length])


    const file_name = ""; //elem.doc !== undefined ? elem.doc[0].name :  null;

    const onSubmit = data => {

        console.log("-onSubmit-notion--101010101-->>>");

        // return;
        const {
                description     :  content  ,
                media           :  media_path,
                title,
                id              =  elem.id ,
                element_order   =  elem.element_order,
                // doc :
               module_id        = module.id,
               current_course_id=current_course.id
             } = data;

        const doc = attached_documents.filter(elem=> elem instanceof File );

        const notion = {content,media_path,title,id,element_order,doc,module_id,current_course_id};

        dispatch(editNotion(notion));
        setAttachedDocuments([])

    };


    const deleteAttachment = (id)=>{
        if(id)
        dispatch(deleteAttachedDocuments({id:id,notion_id:elem.id,module_id:module.id}))
    }


    const deleteNotionButton =() => {

           setIsOpenDelete(false)

            if (elem.id) {
                dispatch(deleteNotion(module.id, elem.id, current_course.id,() => {}))

            } else {
                deleteItem(elem._id)
            }


        }

    return <div className={`body_element_notion ${isCollapse ? "isCollapse" : ""}`}>
        <DeleteElementsModal  isOpen={is_open_delete} onCancel={()=> setIsOpenDelete(false)} onDelete={deleteNotionButton} />

        <div
            className="NE_collapsable_card_with_icon NE_collapsable_card_with_icon_open notion_creation_card">
            <div className="header">
                <div className="title">
                    <img src={iconNotion}
                         alt="Container icon"/>
                    <h2>Notion</h2>
                </div>
                {!current_course.publish && <div className="delete_element_content">
                   <span className="btn_delete_element" onClick={() => {
                       setIsOpenDelete(true)
                       //
                   }}>

                   </span>
                    <span className="btn_collapse" onClick={() => {
                        setIsCollapse(!isCollapse)
                    }}/>
                </div>}

            </div>

            <div className="body">
                <form action="#" method="post" encType="multipart/form-data">
                    <div className="form-field">
                        <div className="NE_normal_input">
                            <label htmlFor="title">Title</label>
                            <input type="text" {...register("title", {required: true})} placeholder="Notion title"/>
                            {errors.title?.type === 'required' && (
                                <span className="error_msg">Title is required</span>)}


                        </div>
                    </div>

                    <div className="form-field">
                        <div className="NE_normal_input">
                            <label htmlFor="media">Media</label>
                            <input type="text" id="media" {...register("media")} placeholder="Add media link"/>
                        </div>
                    </div>

                    <div className="form-field">
                       <div className="file">
                           <div className="NE_documents_uploader">
                               <input type="file"  {...register("doc")} multiple accept=".pdf,.ppt,.pptx,.doc,.docx,.xls,.xlsx,.pptm,.xlsm"/>
                               <div className="drop_zone">
                                   <img
                                       src={iconAddFile}
                                       alt="Add document icon"/>
                                   <h1>
                                       {file_name ?
                                           file_name
                                           :
                                           'Attached Documents'
                                       }
                                   </h1>
                                   <p>Drag & drop or <span>browse</span> your file here</p>
                               </div>
                           </div>
                           <div className="upload_vault">
                               {attached_documents?.map((item,index) => {
                                   return (
                                       <div className="upload_item" key={index}>
                                           <div className="upload_item_icon">
                                               <img src={imageCloud} alt="Upload"/>
                                           </div>

                                           <div className="upload_item_content">
                                               <div className="upload_item_header">
                                                   <h1>{item.title ? item.title : (item.name ? item.name: "")}</h1>
                                                   <button onClick={(e) => {
                                                       e.preventDefault();
                                                       const list =    attached_documents.filter((item,index_)=> index_ !== index );
                                                       setAttachedDocuments(list);

                                                       deleteAttachment(item.id)
                                                   }}>Cancel</button>
                                               </div>

                                               {/*<div className="upload_item_progress">*/}
                                               {/*    <div className="progress_bar">*/}
                                               {/*        <div className="progress_level" style={{width:"65%"}}></div>*/}
                                               {/*    </div>*/}

                                               {/*    <div className="progress_infos">*/}
                                               {/*        <p className="progress_title">Uploading…</p>*/}
                                               {/*        <p className="progress_percentage">64%</p>*/}
                                               {/*    </div>*/}
                                               {/*</div>*/}
                                           </div>
                                       </div>
                                   );
                               })}
                           </div>
                       </div>
                    </div>

                    <div className="form-field mb-1">
                        <Controller
                            control={control}
                            name="description"
                            rules={{required: true}}
                            render={({field: {onChange, onBlur, value, ref}}) => {

                            return (
                                <RichEditor value={elem.content ?? ""} onChange={onChange}/>
                            )
                            }
                            }
                        />
                    </div>
                    {errors.description?.type === 'required' && (
                        <span className="error_msg">Description is required</span>)}

                    {showButtons &&
                    <div className="actions">
                        <div className="action">
                            <button className="NE_button_large NE_button_outline"
                                    type="reset">Cancel
                            </button>
                        </div>

                        <div className="action">
                            <button className="NE_button_large NE_button_dark" type="button"
                                    onClick={handleSubmit(onSubmit)}
                            >Save
                            </button>
                        </div>
                    </div>}
                </form>
            </div>
        </div>
    </div>;
}

function ControlBtnComponent({onAddElement = () => null}) {
    const [isOpen, setIsOpen] = useState(false);
    return <div className="NE_toggle_bar_button NE_toggle_bar_button_open">
        <div className="toggle_button" onClick={() => setIsOpen(!isOpen)}>
            <img src={add} alt="Toggle button"/>
        </div>

        {isOpen && <div className="actions_bar actions_bar_st">
            <div className="action" onClick={onAddElement("notion")}>
                <div className="notion_icon"/>
                <p>Notion</p>
            </div>

            <div className="action" onClick={onAddElement("quiz")}>
                <div className="quiz_icon"/>
                <p>Quiz</p>
            </div>
        </div>}
    </div>;
}

function ListComponent(props) {
    const {items, addItem ,loadData} = useContext(ModuleItemContext);

    const addItem_ = (type) => (e) => {
        addItem({
            type,
            reference_object:type,
            _id: uid(),
            element_order: items.length
        });
    }
    useEffect(() => {
        loadData(props.listElements)
    }, [props.listElements]);

    return <div className="body">
                {items.map(props.callbackfn)}
                {!props.current_course.publish && <ControlBtnComponent onAddElement={addItem_}/>}
            </div>;
}

ListComponent.propTypes = {
    listElements: PropTypes.arrayOf(PropTypes.any),
    callbackfn: PropTypes.func,
    onAddElement: PropTypes.func
};
const ModuleCard = (props) => {
    const {current_course , isOpen = false , editMode } = props //useSelector(state => state.moderator_configure_course)

    const [listElements, setListElements] = useState([]);
    const [is_open, setIs_open] = useState(isOpen);
    const [is_open_delete, setIsOpenDelete] = useState(false);
    const [edit_title , setEditTitle] = useState(false);
    const _title = useRef()
    const [moveElement , indexItem , length] = useMoveElementCourse({element:props.element,current_course})

    useEffect(() => {

        setListElements(props.element.items.map((elem)=>({...elem,_id:uid()})))

    }, [props.element]);


    useEffect(() => {
        if (_title.current) {
            _title.current.value = props.element?.title;
        }
    }, [_title,edit_title]);


    const onAddElement = (type) => (e) => {

        setListElements([...listElements, {
            type
        }])
    }

    const {element, onSave} = props;


    let class_name = "NE_collapsable_card_with_icon quiz_creation_card ";
    class_name += is_open ? "NE_collapsable_card_with_icon_open" : "";


    const dispatch = useDispatch()

    const deleteEvaluationButton = () => {


        setIsOpenDelete(false);
        dispatch(
            deleteModule(element.id)
        )
    }


    const editTitle = () => {
        if (edit_title){
            setEditTitle(false);
            const {element} = props;

            const _item = {
                id:element.id ,
                title:_title.current.value,
                description:element.description,
                element_order:element.element_order,
                published:element.published,
                type:element.type
            }

            dispatch(editItemCourse(_item,"module"))
            return;
        }
        setEditTitle(true)
    }



    // TODO: add new quiz
    // TODO: delete notion
    // TODO: fix new item is always quiz
    return <ModuleItemsProvider>
        <DeleteElementsModal  isOpen={is_open_delete} onCancel={()=> setIsOpenDelete(false)} onDelete={deleteEvaluationButton} />

        <div className={class_name}>
            <div className="header">
                <div className="title">

                       <span className={"d-flex"} >
                              {<img src={arrowUp} className={`arrow`} alt={""} onClick={() => indexItem !== 0 ?  moveElement("down") : null}/>}
                              {<img src={arrowDown} className={`arrow `} alt={""} onClick={() => length-1 !== indexItem ? moveElement("up") : null}/>}
                        </span>

                    <img src={icon} alt="Container icon"/>
                    {edit_title ?
                        <div className="form-field">
                            <div className="NE_normal_input">
                                <input
                                    ref={_title}
                                    onChange={(e)=>{
                                        _title.current.value  = e.target.value;
                                    }}
                                    />
                            </div>
                        </div>
                        : <h2>{element?.title}</h2>
                    }
                </div>

                {!current_course.publish && <div className="delete_element_content">
                    <PublishedElement type={"module"} element={props.element}  />
                    <span className={`${ !edit_title ? 'btn_edit' : 'btn_save'}`} onClick={editTitle} />
                    {editMode &&

                        <>

                            <span className="btn_delete_element" onClick={() => {
                                setIsOpenDelete(true);
                            }} />
                        </>
                    }

                    <span className="btn_collapse" onClick={() => setIs_open(!is_open)}/>
                </div>}

            </div>

           <ListComponent  current_course={current_course} listElements={listElements} callbackfn={(elem, index) => {

                if (elem.reference_object === "notion") return <NotionFormComponent current_course={current_course}
                                                                                    elem={elem}
                                                                                    key={index}
                                                                                    index={index}
                                                                                    module={element}/>;

                                                              return <QuizComponent current_course={current_course}
                                                                                    quiz_id={elem._id}
                                                                                    key={index}
                                                                                    elem={elem}
                                                                                    module={element}
                                                                                    index={index}/>;

            }}/>
        </div>
    </ModuleItemsProvider>

}
export default ModuleCard;
