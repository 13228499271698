import React, {Component} from "react";
import {connect, useSelector} from "react-redux";
import {Translation} from "react-i18next";
import './index.css'
import Logo from '../../modules/images/logo.png';
import Alert from "../Alert";
import Loader from "../Loader";
import history from "../../../utils/history";
import IconInput from "../IconInput";
import ProfilePopupCard from "../ProfilePopupCard";
import {logout} from "../../redux/login/action-creators";
import UserInfo from "../../modules/Userinfo";
import participant_points from "../../redux/participant/participant-points/reducer";
import {getProfilePoints} from "../../redux/participant/participant-points/action-creators";
import ProfileProgressBar from "../ProfileProgressBar";
import {
    changeCurrentUserInfoValidationPropValue,
    // changeCurrentUserInfoValue,
    changeUpdateUserValues
} from "../../redux/updateuser/actions-creators";
import {
    description_schema,
    email_schema,
    full_name_schema,
    image_size_schema, language_schema,
    password_schema
} from "../../validation/validations";

class Header extends Component{

    state = {
        is_profile_popup_open: false,
        user_modal_open: false
    }

    handleAvatarClick = () => {
        this.setState({
            ...this.state,
            is_profile_popup_open: !this.state.is_profile_popup_open
        })
    }

    handleProfileClick = () => {

        history.push("/participant/profile");
        this.clearLocalState();
    }

    handleSettingsClick = () => {

        // history.push("/participant/edit-profile");
        // this.clearLocalState();
        const {user_modal_open, is_profile_popup_open} = this.state
        this.setState({
            user_modal_open: !user_modal_open,
            is_profile_popup_open: !is_profile_popup_open
        })
        // this.handleFillCurrentUser();
        // this.clearLocalState();
    }

    handleLogoutClick = () => {


        // Dispatch action to log user out
        this.props.logout();

        this.clearLocalState();
    }

    handleAddClientModalCancel = () => {
        const {user_modal_open} = this.state
        this.setState({
            user_modal_open: !user_modal_open,

        })
    }

    clearLocalState = () => {
        this.setState({
            ...this.state,
            is_profile_popup_open: false,
        })
    }

    validateCurrentUserInfoProp = (propName,propValue) => {

        // Validate and Set the field value in the local state
        const {changeCurrentUserInfoValidationPropValue} = this.props
        const {newpassword} = this.props.user_information;
        switch (propName) {

            case 'avatar_path':
                const image_error = propValue ? "" : "Avatar is required";
                const {error: image_size_error} = image_size_schema.validate((propValue && propValue.size) ?? 0);
                changeCurrentUserInfoValidationPropValue(propName,{
                    is_valid: !image_size_error && image_error === "",
                    errors: [
                        image_error,
                        image_size_error ? image_size_error.message : ""
                    ]
                });
                break;

            case 'full_name':
                const {error: full_name_error} = full_name_schema.validate(propValue);
                changeCurrentUserInfoValidationPropValue(propName,{
                    is_valid: !full_name_error,
                    error: full_name_error ? full_name_error.message : ""
                });
                break;

            case 'description':
                const {error: description_error} = description_schema.validate(propValue);
                changeCurrentUserInfoValidationPropValue(propName,{
                    is_valid: !description_error,
                    error: description_error ? description_error.message : ""
                });
                break;

            case 'language':
                const {error: language_error} = language_schema.validate(propValue);
                changeCurrentUserInfoValidationPropValue(propName,{
                    is_valid: !language_error,
                    error: language_error ? language_error.message : ""
                });
                break;

            case 'email':
                const {error: email_error} = email_schema.validate(propValue);
                changeCurrentUserInfoValidationPropValue(propName,{
                    is_valid: !email_error,
                    error: email_error ? email_error.message : ""
                });
                break;

            case 'password':
                const {error: password_error} = password_schema.validate(propValue);
                changeCurrentUserInfoValidationPropValue(propName,{
                    is_valid: !password_error,
                    error: password_error ? password_error.message : ""
                });
                break;


            case 'newpassword':
                const {error: newpassword_error} = password_schema.validate(propValue);
                changeCurrentUserInfoValidationPropValue(propName,{
                    is_valid: !newpassword_error,
                    error: newpassword_error ? newpassword_error.message : ""
                });
                break;

            case 'repeat_newpassword':
                let {error: repeat_newpassword_error} = password_schema.validate(propValue);

                if(!repeat_newpassword_error){
                    if(propValue !== newpassword){
                        repeat_newpassword_error = {
                            message:"Password doesn't match"
                        }
                    }
                }
                changeCurrentUserInfoValidationPropValue(propName,{
                    is_valid: !repeat_newpassword_error,
                    error: repeat_newpassword_error ? repeat_newpassword_error.message : ""
                });
                break;

            default:
                break
        }
    }

    handleCurrentClientPropChange = (propName,propValue) => {
        const {changeUpdateUserValues} = this.props;

        // Dispatch action to change current client prop value
        changeUpdateUserValues(propName,propValue);

        this.validateCurrentUserInfoProp(propName,propValue);

    };


    isFormValid = () => {
        const {current_user} = this.props.login;
        const {avatar_path,full_name,description,password, newpassword, repeat_newpassword, language} = this.props.user_information.current_userinfo_validation;

        if (current_user?.authentication_mode === 0 )
            return (avatar_path.is_valid || full_name.is_valid || description.is_valid || language.is_valid) && password.is_valid || (newpassword.is_valid && repeat_newpassword.is_valid);
        else
            return avatar_path.is_valid || full_name.is_valid || description.is_valid || language.is_valid;
    };

    handleFillCurrentUser = () => {
        // const {changeCurrentUserInfoValue} = this.props
        // const {description, email, full_name, avatar_path, language} = this.props.login.current_user
        // changeCurrentUserInfoValue({description, email, full_name, avatar_path, language});


    }

    componentDidMount() {
        this.handleFillCurrentUser();
    }

    render() {

        // const {alerts} = this.props.user_information
        const {searchData,alerts,isLoading,onCloseAlert=()=>{}} = this.props;
        const {is_profile_popup_open} = this.state;
        const {current_user} = this.props.login;
        const {current_userinfo, current_userinfo_validation} = this.props.user_information
        const {profilePoints} = this.props.participant_points;
        const score =  profilePoints?.user_scores?.total_score;

        let profile_popup_class_name = "profile_popup_container ";
        profile_popup_class_name += is_profile_popup_open ? "profile_popup_container_open" : "";

        return <Translation>
            {t => {
                // Prepare profile pop up options data
                const profile_popup_options_data = [];

                if(current_user?.role.name.toUpperCase() === "PARTICIPANT"){
                    profile_popup_options_data.push({
                        name: t("header.profile_option"),
                        click_handler: this.handleProfileClick
                    });
                }

                profile_popup_options_data.push(
                    {
                        name: t("header.settings_option"),
                        click_handler: this.handleSettingsClick
                    },
                    {
                        name: t("header.logout_option"),
                        click_handler: this.handleLogoutClick
                    }
                );

                return (
                    <>
                        <Loader isOpen={isLoading} />

                        <div className="NE_header">
                            <div className="container">
                                <div className="logo">
                                    <a href="#" onClick={(e) => {e.preventDefault();history.push("/");}}>
                                        <img src={Logo} alt="Logo" />
                                    </a>
                                </div>
                                <div className="actions">
                                    {(history.location.pathname === "/moderator" || history.location.pathname === "/participant" || history.location.pathname === "/admin")  && (
                                        <IconInput type="text" placeholder={searchData?.placeholder} value={searchData?.search_string} onChange={searchData?.onChange} />
                                    )}

                                    <div className="NE_avatar" onClick={this.handleAvatarClick}>
                                        <img src={current_user?.avatar_path ? current_user?.avatar_path : `https://avatars.dicebear.com/api/initials/${current_user?.full_name}.svg?radius=50&size=34`} alt={current_user?.full_name} />
                                    </div>

                                    {current_user?.role.name.toUpperCase() === "PARTICIPANT" && (
                                            <ProfileProgressBar score={score}/>
                                    )}

                                </div>

                                <div className={profile_popup_class_name}>
                                   {is_profile_popup_open && <div onClick={()=>this.setState({is_profile_popup_open:false})} className={"close_md"}/>}
                                    <ProfilePopupCard user={current_user} options={profile_popup_options_data} />
                                </div>
                            </div>


                            <div className="alerts_container">
                                {alerts?.map(alert => {
                                    return <Alert key={alert.id} type={alert.type} message={alert.message} onClose={() => {onCloseAlert(alert.id)}} />
                                })}
                            </div>
                        </div>
                        <UserInfo
                            isOpen={this.state.user_modal_open}
                            current_userinfo={current_userinfo}
                            current_userinfo_validation={current_userinfo_validation}
                            isFormValid={this.isFormValid()}
                            onChange={this.handleCurrentClientPropChange}
                            onCancel={this.handleAddClientModalCancel}
                        />
                    </>

                );
            }}
        </Translation>
    }
}

const mapStateToProps = state => {
    const {login, participant_points, user_information } = state;

    return {login, participant_points, user_information};
};

const mapActionsToProps = {
    // changeCurrentUserInfoValue,
    changeCurrentUserInfoValidationPropValue,
    changeUpdateUserValues,
    getProfilePoints,
    logout
};
export default connect(mapStateToProps,mapActionsToProps)(Header);
