import CryptoJS from "crypto-js";
import queryString from 'query-string';

export const configGames = {
  "PV5Game": {
    domain: process.env.REACT_APP_DOMAIN_PV5_GAME,
    port: process.env.REACT_APP_PORT_PV5_GAME
  },
  "LeadEdge": {
    domain: process.env.REACT_APP_DOMAIN_LEADEDGE,
    port: process.env.REACT_APP_PORT_LEADEDGE
  },
  "PVChallenge": {
    domain: process.env.REACT_APP_DOMAIN_PV_CHALLENGE,
    port: process.env.REACT_APP_PORT_PV_CHALLENG
  },
  "Insights": {
    domain: process.env.REACT_APP_DOMAIN_INSIGHT,
    port: process.env.REACT_APP_PORT_INSIGHT
  },
  "DigitalAmbassadors": {
    domain: process.env.REACT_APP_DOMAIN_DIGITAL_AMBASSADORS,
    port: process.env.REACT_APP_PORT_DIGITAL_AMBASSADORS
  },
  "PVGame": {
    domain: process.env.REACT_APP_DOMAIN_PV_GAME,
    port: process.env.REACT_APP_PORT_PV_GAME
  }
}



function redirectToApp(gameSessionId, gameName, user) {

  const token = localStorage.getItem("NowEdge.current_user_token");
  const secretPass = process.env.REACT_APP_SECRET_PASS;

  const game = configGames[gameName];



  if (!game) return null;

  let token_ = CryptoJS.AES.encrypt(
    JSON.stringify({
      token,
      gameSessionId,
      role: user.role.id === 3 ? "participant" : "moderator",
      user: user
    }),
    secretPass
  ).toString();

  const parmaUrlStringify = queryString?.stringify({
    token: token_
  });

  if (process.env.NODE_ENV === "development") {
    const param = `loaderPage?${parmaUrlStringify}`;
    return `http://localhost:${game.port}/${param}`;
  }
  return `${game.domain}/loaderPage?${parmaUrlStringify}`;

}


// function redirectToApp(gameSessionId, gameName) {

//   const token = localStorage.getItem("NowEdge.current_user_token");
//   const secretPass = process.env.REACT_APP_SECRET_PASS;

//   const game = configGames[gameName];

//   if (!game) return null;

//   let token_ = CryptoJS.AES.encrypt(
//     JSON.stringify({
//       token,
//       gameSessionId,
//       role: "participant"
//     }),
//     secretPass
//   ).toString();

//   const parmaUrlStringify = queryString?.stringify({
//     token: token_
//   });

//   if (process.env.NODE_ENV === "development") {
//     const param = `loaderPage?${parmaUrlStringify}`;
//     return `http://localhost:${game.port}/${param}`;
//   }
//   return `${game.domain}/loaderPage?${parmaUrlStringify}`;

// }


export {
  redirectToApp
}


// import CryptoJS from "crypto-js";
// import queryString from 'query-string';

// export const configGames = {
//   "PV5Game": {
//     domain: process.env.REACT_APP_DOMAIN_PV5_GAME,
//     port: process.env.REACT_APP_PORT_PV5_GAME
//   },
//   "LeadEdge": {
//     domain: process.env.REACT_APP_DOMAIN_LEADEDGE,
//     port: process.env.REACT_APP_PORT_LEADEDGE
//   },
//   "PVChallenge": {
//     domain: process.env.REACT_APP_DOMAIN_PV_CHALLENGE,
//     port: process.env.REACT_APP_PORT_PV_CHALLENG
//   },
//   "PVChallengePlus": {
//     domain: process.env.REACT_APP_DOMAIN_PV_CHALLENGE,
//     port: process.env.REACT_APP_PORT_PV_CHALLENG
//   },
//   "Insights": {
//     domain: process.env.REACT_APP_DOMAIN_INSIGHT,
//     port: process.env.REACT_APP_PORT_INSIGHT
//   },
//   "DigitalAmbassadors": {
//     domain: process.env.REACT_APP_DOMAIN_DIGITAL_AMBASSADORS,
//     port: process.env.REACT_APP_PORT_DIGITAL_AMBASSADORS
//   },
//   "PVGame": {
//     domain: process.env.REACT_APP_DOMAIN_PV_GAME,
//     port: process.env.REACT_APP_PORT_PV_GAME
//   },
//   "SustainUp": {
//     domain: process.env.REACT_APP_DOMAIN_SUSTAIN_UP,
//     port: process.env.REACT_APP_PORT_SUSTAIN_UP
//   },
//   "HackerMind": {
//     domain: process.env.REACT_APP_DOMAIN_HACKERMIND,
//     port: process.env.REACT_APP_PORT_HACKERMIND
//   },
//   "StratEdge": {
//     domain: process.env.REACT_APP_DOMAIN_STRAT_EDGE,
//     port: process.env.REACT_APP_PORT_STRAT_EDGE
//   },
//   "FinEdge": {
//     domain: process.env.REACT_APP_DOMAIN_FIN_EDGE,
//     port: process.env.REACT_APP_PORT_FIN_EDGE
//   },
//   "PVChallengeOfficine": {
//     domain: process.env.REACT_APP_DOMAIN_PV_CHALLENGE_OFFICINE,
//     port: process.env.REACT_APP_PORT_PV_CHALLENG_OFFICINE
//   },
//   "P2pGame": {
//     domain: process.env.REACT_APP_DOMAIN_P2P_GAME,
//     port: process.env.REACT_APP_PORT_P2P_GAME
//   }
// }