import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { redirectToApp } from "./redirectToApp";


export default function useGetGame(clickedItem) {

    const [game, setGame] = useState({});
    const [url, setUrl] = useState("");

    const { trainingSession } = useSelector(
        (state) => state.participant_course_item
    );

    const { selected_session, current_dashboard_selected_element } = useSelector(
        (state) => state.moderator_training_sessions
    );


    const { current_user } = useSelector(
        (state) => state.login
    );

    useEffect(() => {

        if (clickedItem?.type.toUpperCase() !== "BUSINESS_GAME") {
            setGame(null);
            setUrl("")
        }

        if (clickedItem?.id && current_user?.role?.id === 3) {
            // if (clickedItem) {

            const game = trainingSession?.content?.find((item) => item.id === clickedItem.id) ?? {};

            if (game) {
                setGame(game);

                const url_ = redirectToApp(game.game_session_id, game.game_name, current_user);

                console.log("urlll", url_)
                setUrl(url_);
            }


        } else if (current_user?.role?.id === 2) {

            const game_session = selected_session?.game_sessions?.find(item => item?.game_configuration_id === clickedItem?.id)
            if (clickedItem?.title && game_session) {
                setGame(clickedItem);
                const url_ = redirectToApp(game_session.id, clickedItem.title, current_user);
                setUrl(url_);
            }
        }


    }, [clickedItem?.id, trainingSession])

    return { game, url, trainingSession };
}