import React,{Component} from "react";
import {Translation} from "react-i18next";
import './index.css'

class StudentsList extends Component{

    state = {
        active_element: null,
    }

    handleActiveElementChange = (element) => {
        const {onActiveElementChange} = this.props;

        this.setState({
            ...this.state,
            active_element:element
        });

        onActiveElementChange(element);
    }

    render() {
        const {elements} = this.props;
        console.log('elements',elements);

        return <Translation>
            {t => {
                return (
                    <div className="NE_students_list">
                        <div className="list_header">
                            <p>{elements?.length} {t("moderator.training-sessions.statics.session-dashboards.dashboard.elements_list_title")}</p>
                        </div>
                        <div className="list_items">
                            {elements?.map(element => {
                                const is_active = this.state.active_element?.id === element.id;
                                let class_name = "list_item ";
                                class_name += is_active ? "list_item_active" : "";

                                return (
                                    <div key={element.id} className={class_name} onClick={() => {this.handleActiveElementChange(element)}}>
                                        <img src={element?.image_path ? element.image_path : `https://avatars.dicebear.com/api/initials/${element?.full_name}.svg?radius=50&size=42`} alt="Student" />
                                        <p>{element?.full_name}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            }}
        </Translation>
    }
}
export default StudentsList;