// import React, { Component } from "react";
// import Question from "../Question";
// import "./index.css";
// import imgSVG from "./assets/Group_10279.svg";

// const EtudiantBusinessGame = ({ clickedItem, discussions, onClickItem }) => {
//   return (
//     <div class="NE_business_game">

//       <div className="NE_notionItem_header_button" style={{
//         height: "auto"
//       }}>
//         <div className="active_button">
//           <span>Course</span>
//         </div>
//         <div
//           className="disabled_button"
//           onClick={() => {
//             const disc = discussions?.find(
//               (d) =>
//                 d.element_type === "business_game" &&
//                 d.element_id === clickedItem.id
//             );
//             onClickItem({
//               type: "discussion",
//               ...disc,
//               course: clickedItem,
//             });
//           }}
//         >
//           <span>Discussion</span>
//         </div>
//       </div>
//       <img src={imgSVG} alt="" />
//       <h1>Business game 1</h1>
//       <div class="textsize">
//         <p>
//           Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
//           nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
//           sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
//           rebum
//         </p>
//       </div>
//       <div class="button">
//         <p>Business game</p>
//       </div>
//     </div>
//   );
// };
// export default EtudiantBusinessGame;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DocumentLine from "../DocumentLine";
import "./index.css";
import imgSVG from "./assets/Group_10279.svg";
import { getMessages } from "../../redux/participant/participant-course-item/action-creators";
import { redirectToApp } from "../../../utils/redirectToApp";
import useGetGame from "../../../utils/useGetGame";



const EtudiantBusinessGame = ({ clickedItem, discussions, onClickItem, callbackfn = () => null }) => {


  // const [url , setUrl] = useState();
  // const [game , setGame] = useState({});
  const [documents, setDocuments] = useState(false);
  const dispatch = useDispatch();

  const { trainingSession } = useSelector(
    (state) => state.participant_course_item
  );

  const { game, url } = useGetGame(clickedItem);

  //   useEffect(() => {
  //       dispatch(getMessages(clickedItem?.course?.id));
  //       setDocuments(
  //       trainingSession?.content?.find((c) => c.id === clickedItem?.id)
  //     );
  //   }, [clickedItem]);

  //   useEffect(()=>{
  //     const game =   trainingSession.content.find((item)=> item.id === clickedItem.id);

  //     setGame(game);

  //     const url_ = redirectToApp(game.id,game.game_name);
  //     setUrl(url_);

  // },[clickedItem?.id])


  console.log("---game---", game);

  return (
    <div
      style={{
        flex: 3,
        display: "flex",
        // paddingBottom: 100,
        marginLeft: 19
      }}
      className="NE_notionItem_container"
    >

      <div class="NE_business_game">
        <img src={game?.go_path ?? imgSVG} alt="" />
        <h1>{game?.game_name}</h1>
        <div class="textsize">
          <p>{game?.game_description}</p>
        </div>
        <a class="button" href={url} target="_blank" >
          <p>{game.game_name}</p>
        </a>
      </div>

      {/*
      <div
          style={
            {
              display: "flex",
              flex: 5,
              flexDirection: "column",
              marginTop: "5rem",
              width: "100%"
            }
          }
        >

        </div> */}
    </div>
  );
};

export default EtudiantBusinessGame;
