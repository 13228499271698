import React, { Component } from "react";
import { connect } from "react-redux";
import './index.css'
import Header from "../../components/Header";
import Textarea from "../../components/Textarea";
import NormalInput from "../../components/NormalInput";
import NormalButton from "../../components/NormalButton";
import avatar from "./assets/avatar.png"
import Dropdown from "../../components/Dropdown";
import add from "../../components/ImagesUploader/assets/add.svg";
import {
    changeCurrentClientValidationPropValue,
    changeUpdateUserValues,
    updateUser
} from "../../redux/updateuser/actions-creators";
import Dropdown2 from "../../components/Dropdown2";
import {
    description_schema,
    email_schema,
    full_name_schema,
    image_size_schema,
    password_schema
} from "../../validation/validations";
import axios from "axios";
import Alert from "../../components/Alert";
import { Translation } from "react-i18next";
import Loader from "../../components/Loader";

class UserInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preview: '',
            show: false,
            small: true,
            is_form_valid: true,
            passwords_match: true,
            avatarLogo: avatar,
            is_loading: false
        }
    }


    componentWillReceiveProps = (nextProps) => {
        const { current_userinfo } = this.props;

        if (nextProps.current_userinfo.email !== current_userinfo.email) {
            this.setState({
                avatarLogo: nextProps.current_userinfo.avatar_path
            })
        }
    }

    handleImageChange = (e) => {
        this.onChane();
        const { changeUpdateUserValues, onChange } = this.props
        this.setState({
            preview: URL.createObjectURL(e.target.files[0])
        })
        onChange("avatar_path", e.target.files[0])
        this.setState({
            avatarLogo: URL.createObjectURL(e.target.files[0])
        })





        //
        // changeUpdateUserValues("avatar_path", e.target.files[0])
    }

    handleChangePropValue = (propName, PropValue) => {
        const { changeUpdateUserValues } = this.props
        //
        changeUpdateUserValues(propName, PropValue)

    }

    handleShowInput = () => {
        const { show, small } = this.state
        this.setState({
            show: !show,
            small: !small,
        })
    }

    dataFromChildDropdown = (data) => {
        const { onChange } = this.props
        onChange('language', data.name)
        this.onChane();
    }

    handleSubmit = () => {
        this.setState({
            is_loading:true
        })
        const { current_userinfo } = this.props.user_information;


        if (current_userinfo.repeat_newpassword !== current_userinfo.newpassword && this.state.show) {
            return this.setState({
                passwords_match: false
            })
        }



        this.props.updateUser(current_userinfo,()=>{
            this.setState({
                is_loading:false
            })
           setTimeout(()=> this.props.onCancel(),1000)
        });
    }

    onChane = () => {
        this.setState({
            is_form_valid: false
        });
    };

    handleFillObj = () => {
        const current_user = JSON.parse(localStorage.getItem('NowEdge.current_user'));
        const { changeUpdateUserValues } = this.props;
        changeUpdateUserValues("description", current_user.description || "")
        changeUpdateUserValues("full_name", current_user.full_name)
        changeUpdateUserValues("language", current_user.language)
        changeUpdateUserValues("email", current_user.email)
        changeUpdateUserValues("avatar_path", current_user.avatar_path)

    }

    componentDidMount() {
        this.handleFillObj();

        this.setState({
            avatarLogo: this.props.current_userinfo.avatar_path
        })
    }

    render() {
        const { isOpen, onCancel, onChange, current_userinfo, current_userinfo_validation, isFormValid } = this.props;
        const { preview, small } = this.state;
        const current_user = JSON.parse(localStorage.getItem('NowEdge.current_user'));
        let class_name = "header_modal_wrapper ";
        class_name += isOpen ? "header_modal_open" : "";

        //
        let userinfo_content_small = "userinfo_content";
        small ? userinfo_content_small = 'userinfo_content_small' : userinfo_content_small = 'userinfo_content';
        return (
            <Translation>
                {t =>
                    <>
                        <Loader isOpen={this.state.is_loading} />

                        <div className={class_name} onClick={(e) => {
                            if (e.target.className === class_name) {
                                onCancel();
                                // this.clearLocalState();
                            }
                        }}>

                            <div className="userinfo_page_body">
                                <div className={current_user.authentication_mode === 1 || current_user.authentication_mode === 2 ? 'userinfo_content_fb_gl' : userinfo_content_small} >

                                    <div className="alerts_container alerts_container_user">
                                        {this.props.user_information.alerts?.map(alert => {
                                            return <Alert key={alert.id} type={alert.type} message={alert.message} onClose={() => null} />
                                        })}
                                    </div>

                                    <div className="userinfo_NE_header_edit">
                                        <div className={"userinfo_NE_title"}>
                                            <h1>{t("participant.user-info.title")}</h1>
                                        </div>
                                        <label className="" htmlFor="user_upload-button">
                                            <input hidden />
                                            <img src={this.state.avatarLogo} alt="" />
                                            <p>{t("participant.user-info.change_picture_text")}</p>
                                            <input id="user_upload-button" type="file" onChange={this.handleImageChange} accept=".png,.jpeg,.jpg" hidden />
                                            {current_userinfo_validation.avatar_path.error && (current_userinfo_validation.avatar_path.isValid !== undefined) ? <p>{current_userinfo_validation.avatar_path.error}</p> : ""}
                                        </label>
                                    </div>
                                    <div className="userinfo_description">
                                        <Textarea
                                            onKeyPress={() => this.onChane()}
                                            value={current_userinfo?.description} isValid={current_userinfo_validation?.description.is_valid} message={current_userinfo_validation?.description.error} label={"Description"} placeholder={"Placeholder"} onChange={(event) => {
                                                onChange("description", event.target.value || "");

                                            }} />
                                    </div>
                                    <div className="userinfo_h">
                                        <NormalInput value={current_userinfo.full_name}
                                            onKeyPress={() => this.onChane()}
                                            isValid={current_userinfo_validation?.full_name.is_valid} message={current_userinfo_validation?.full_name.error} label={t("participant.user-info.fullname")} placeholder={"Placeholder"} onChange={(event) => {
                                                onChange("full_name", event.target.value);
                                            }} />
                                    </div>
                                    <div className="userinfo_h">
                                        <NormalInput value={current_userinfo.email} isValid={current_userinfo_validation?.email.is_valid} message={current_userinfo_validation?.email.error} label={"Email"} placeholder={"placeholder"} type="email" onChange={(event) => { onChange("email", event.target.value); this.onChane(); }} disabled />
                                    </div>
                                    <div className="userinfo_h">
                                        <Dropdown2
                                            onChange={this.dataFromChildDropdown} value={current_userinfo.language} route={'userupdate'} label={t("participant.user-info.langage")} placeholder="Choose a langage" items={[{ id: 0, name: 'en', disabled: false }, { id: 1, name: 'fr', disabled: false }]} id="category" />
                                        {/*<NormalInput label={"Password"}/>*/}
                                    </div>

                                    {
                                        current_user.authentication_mode === 1 || current_user.authentication_mode === 2 ? '' : <div className="userinfo_h">
                                            <div className="header_align_change_password">
                                                <NormalInput
                                                    onKeyPress={() => this.onChane()}
                                                    defaultValue={"**********"} isValid={current_userinfo_validation?.password.is_valid} message={current_userinfo_validation?.password.error} type="password" label={t("participant.user-info.password")} onChange={(event) => { onChange("password", event.target.value); }} />
                                                <p className="userinfo_change_password" onClick={this.handleShowInput}>{t("participant.user-info.password_change")}</p>
                                            </div>
                                        </div>
                                    }

                                    {this.state.show && <div className="userinfo_hh">
                                        <div className="userinfo_h">
                                            <div className="userinfo_h_">
                                                <NormalInput
                                                    onKeyPress={() => {
                                                        this.onChane();
                                                        this.setState({
                                                            passwords_match: true
                                                        })
                                                    }}
                                                    type="password" label={t("participant.user-info.new_password")} onChange={(event) => { onChange("newpassword", event.target.value); }} />
                                                {!this.state.passwords_match && <span className="error_password_match">
                                                    {t("participant.user-info.passwords-not-matching")}
                                                </span>}
                                            </div>
                                            <div className="userinfo_h_">
                                                <NormalInput
                                                    onKeyPress={() => {
                                                        this.onChane();
                                                        this.setState({
                                                            passwords_match: true
                                                        })
                                                    }}
                                                    type="password" label={t("participant.user-info.repeat_new_password")} onChange={(event) => { onChange("repeat_newpassword", event.target.value); }} />
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <div className="userinfo_footer">
                                        <div className="userinfo_hwidth">
                                            <NormalButton button={"normal"} value={t("participant.user-info.cancel_button")} onClick={onCancel} />
                                        </div>
                                        <div className="userinfo_vwidth">

                                            <NormalButton button={"dark"} value={t("participant.user-info.save_button")}  disabled={this.state.is_form_valid} onClick={this.handleSubmit} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>

                }
            </Translation>
        )
    }
}

const mapStateToProps = (state) => {
    const { user_information } = state
    return { user_information }
};

const mapActionsToProps = {
    updateUser,
    changeUpdateUserValues,
};

export default connect(mapStateToProps, mapActionsToProps)(UserInfo);
