import React, {Component} from "react";
import {Translation} from "react-i18next";
import './index.css'

import iconLens from './assets/Icon-Lens.svg'
import IconInput from "../../../../../components/IconInput";
import LargeButton from "../../../../../components/LargeButton";
import ModeratorCard from "../../../../../components/ModeratorCard";
class ChooseModeratorModal extends Component{

    state = {
        selected_moderators: []
    }

    handleSelectModerator = (moderator) => {
        // add or remove moderator from selected moderators list
        if(this.state.selected_moderators.map(g => g.id).includes(moderator.id)){
            this.setState({
                ...this.state,
                selected_moderators: this.state.selected_moderators.filter(m => m.id !== moderator.id)
            });
        }else{
            this.setState({
                ...this.state,
                selected_moderators: [...this.state.selected_moderators,{...moderator}]
            });
        }
    }

    clearLocalState = () => {
        this.setState({
            selected_moderators: []
        });
    }

    render() {
        // Get props
        const {moderatorsList,isOpen,searchString,onClose,onAffect,onSearch} = this.props;
        const {selected_moderators} = this.state;

        let class_name = "choose_moderator_modal_wrapper ";
        class_name += isOpen ? "choose_moderator_modal_open" : "";

        return <Translation>
            {t => {
                return (
                    <div className={class_name} onClick={(e) => {
                        if(e.target.className === class_name){
                            onClose();
                            this.clearLocalState();
                        }
                    }}>
                        <div className="NE_body">
                            <div className="NE_text">
                                <h3 className="NE_text_body">{t("admin.client-details.statics.moderators-modal.title")}</h3>
                                <h3 className="NE_text_body" onClick={() => {onClose();this.clearLocalState()}}>x</h3>
                            </div>
                            {/*<div className="NE_body_center"></div>*/}
                            <div className="NE_search_bar">
                                <IconInput placeholder={t("admin.client-details.statics.moderators-modal.fields.search_placeholder")} value={searchString} onChange={onSearch} />
                            </div>
                            <div className="NE_body_content">
                                {moderatorsList.map(moderator => {
                                    return <ModeratorCard key={moderator.id} isSelected={selected_moderators.filter(m => m.id === moderator.id).length === 1} moderator={moderator} onClick={this.handleSelectModerator} />;
                                })}
                            </div>
                            <div className="form-actions">
                                <div className="form-action">
                                    <LargeButton value={t("admin.client-details.statics.moderators-modal.cancel_button")} onClick={() => {onClose(); this.clearLocalState()}}/>
                                </div>

                                <div className="form-action">
                                    <LargeButton button="dark" value={t("admin.client-details.statics.moderators-modal.affect_button")} onClick={() => {onAffect(this.state.selected_moderators);onClose();this.clearLocalState()}} />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Translation>
    }
}
export default ChooseModeratorModal;