import React, {Component} from "react";
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {Translation} from 'react-i18next';
import axios from "axios";
import './index.css'
import logo from '../images/logo.png';
import SocialButton from "../../components/SocialButton";
import NormalInput from "../../components/NormalInput";
import NormalButton from "../../components/NormalButton";
import {changeLoginValidationValues, changeLoginValues, loginUser} from "../../redux/login/action-creators";
import {urlFacebook, urlGoogle} from "../../services/instance";
import Loader from "../../components/Loader";
import {email_schema, password_schema} from "../../validation/validations";
import Alert from "../../components/Alert";
import {closeAlert} from "../../redux/admin/client-details/action-creators";
import background from "../images/background1.webp";
import background2 from "../images/background2.webp";
import background3 from "../images/background3.webp";
import background4 from "../images/background4.webp";
import background5 from "../images/background5.webp";

const imagesBackground = [background, background2, background3,background4,background5]


class Login extends Component {
    constructor(props) {
        super(props);
        this.image = imagesBackground[Math.floor(Math.random() * imagesBackground.length)];
    }


    handleValidSubmit = () => {
        const {email, password} = this.props.login;
        this.props.loginUser({'email': email, 'password': password});
    };

    handleSocialSubmit() {
        async function ttt() {
            await window.location.replace(urlFacebook);
            const url = window.location.href;
            const _url = new URL(url);
            const params = new URLSearchParams(_url.search);
            const t = params.get('code')

        }

        //
        return;
        const url = window.location.href;
        const _url = new URL(url);
        const params = new URLSearchParams(_url.search);
        const t = params.get('code')

        // return;
        // const code = this.GetSocialCode();
        // this.handleFacebook(code);
        // const {_code} = this.props.login;
        // this.props.loginUser({'code': _code});
        // window.location.replace(urlGoogle);

    }

    handleFacebook = (code) => {
        const redirect_uri = "https://127.0.0.1:3000"
        const ob = {
            code: code,
            redirect_uri: redirect_uri
        }
        axios.post(`${process.env.REACT_APP_BACKEND_URL}user/authenticate-with-facebook`, ob).then(response => {
            console.log('from facebook ' + response);
        })
    }

    GetSocialCode = () => {
        const url = window.location.href;
        const _url = new URL(url);
        const params = new URLSearchParams(_url.search);
        const t = params.get('code')
        //
        return t;
    }

    componentDidMount() {

        const code = this.GetSocialCode();
        // const  ob  = {
        //     code : code,
        //     redirect_uri : "https://127.0.0.1:3000"
        // }
        // if (code){
        //     axios.post(`${process.env.REACT_APP_BACKEND_URL}Userinfo/authenticate-with-facebook`, ob).then(response => {
        //
        //     })
        // }

        this.setState(prevState => {
                    return {
                        image: imagesBackground[Math.floor(Math.random() * imagesBackground.length)]
                    };
                });
        const intervalId = setInterval(() => {
            this.setState(prevState => {
                return {
                    image: imagesBackground[Math.floor(Math.random() * imagesBackground.length)]
                };
            });

        }, 20000);

        this.setState({intervalId: intervalId});
    }



    componentWillUnmount(){
        clearInterval(this.state.intervalId);
    }

    validateLoginProp = (propName, propValue) => {
        const {changeLoginValidationValues} = this.props;

        switch (propName) {
            case 'email':
                const {error: email_error} = email_schema.validate(propValue);
                changeLoginValidationValues(propName, {
                    is_valid: !email_error,
                    error: email_error ? email_error.message : ""
                });
                break;

            case 'password':
                const {error: password_error} = password_schema.validate(propValue);
                changeLoginValidationValues(propName, {
                    is_valid: !password_error,
                    error: password_error ? password_error.message : ""
                });
                break;

            default:
                break;
        }
    }

    handleLoginPropChange = (propName, propValue) => {
        const {changeLoginValues} = this.props;

        // Dispatch action to change login prop value
        changeLoginValues(propName, propValue);

        this.validateLoginProp(propName, propValue);
    };

    isFormValid = () => {
        const login_validation = this.props.login.login_validation;

        return true //login_validation?.email?.is_valid && login_validation?.password?.is_valid;
    };


    render() {
        //get Variables from props
        const {email, password, is_loading, login_validation, alerts} = this.props.login;

        return (
            <Translation>
                {t =>
                    <div>
                        <Loader isOpen={is_loading}/>
                        <div className="login_split login_left login_page">
                            <div className="alerts_container">
                                {alerts?.map(alert => {
                                    return <Alert key={alert.id} type={alert.type} destination={'login'}
                                                  message={alert.message} onClose={() => {
                                        this.props.closeAlert(alert.id)
                                    }}/>
                                })}
                            </div>
                            <div className="login_centered">
                                <div className="login_NE_contain">
                                    <img className="login_NE_header_logo" src={logo} alt="Logo"/>
                                    <div>
                                        <h3 className="login_NE_header_">{t("login.title")}</h3>
                                        <p className="login_NE_paragraph">{t("login.sign_up_cta")}</p>
                                        <p className="login_NE_paragraph_underlined">
                                            <Link to="/signup">{t("login.sign_up_link")}</Link>
                                        </p>
                                        <div className="login_NE_label">
                                            <NormalInput
                                                         message={login_validation?.email?.error}
                                                         label={t("login.fields.email_label")}
                                                         placeholder={t("login.fields.email_placeholder")}
                                                         name='email'
                                                         value={email}
                                                         onChange={(event) => {
                                                             this.handleLoginPropChange("email", event.target.value)
                                                         }}/>
                                        </div>
                                        <div className="login_NE_label">
                                            <NormalInput
                                                         label={t("login.fields.password_label")}
                                                         placeholder={t("login.fields.password_placeholder")}
                                                         name='password'
                                                         type='password'
                                                         value={password}
                                                         onChange={(event) => {
                                                             this.handleLoginPropChange("password", event.target.value)
                                                         }}/>
                                        </div>
                                        <div className="login_NE_label_login">
                                            <NormalButton button='dark' value={t("login.sign_in_button")}
                                                          disabled={!this.isFormValid()}
                                                          onClick={this.handleValidSubmit}/>
                                        </div>
                                        <div style={{marginBottom: '82px'}} className="login_NE_label">
                                            <p className="login_NE_paragraph"><a
                                                href="/reset">{t("login.forgot_password_link")}</a></p>
                                        </div>
                                        <div className="login_NE_Social">
                                            {/*<SocialButton type='facebook'*/}
                                            {/*              value={t("login.sign_in_with_facebook_button")}*/}
                                            {/*              onClick={() => window.location.replace(urlFacebook)}/>*/}

                                            {/* <SocialButton type='google'
                                                          value={t("login.sign_in_with_google_button")}
                                                          onClick={() => window.location.replace(urlGoogle)}/> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="login_split login_right" style={{
                            backgroundImage: `url(${this?.state?.image})`
                        }}>
                            <div className="login_centered">

                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = (state) => {
    const {login} = state;
    return {login};
};

const mapActionsToProps = {
    loginUser,
    changeLoginValues,
    changeLoginValidationValues,
    closeAlert
};
// export default Login;
export default connect(mapStateToProps, mapActionsToProps)(Login);

