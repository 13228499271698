import React, {Component} from "react";
import {Translation} from "react-i18next";

import './index.css';
import NormalInput from "../../../../../components/NormalInput";
import NormalButton from "../../../../../components/NormalButton";
import add_picture from "./assets/add-picture.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";


class ClientModal extends Component {
    constructor(props) {
        super(props);
        this.imageUploadInput = React.createRef();
        this.state = {
            startDate: new Date()
        }
    }


    componentWillReceiveProps = (nextProps) => {
        const { currentClient} = this.props;

        if (nextProps.currentClient.id !== currentClient.id && nextProps.currentClient.id) {
            this.setState({
                startDate: new Date(nextProps.currentClient.expiry_date)
            })
        }
    }

    handleDateChange = (date) => {
        const {onChange} = this.props
        const dateFormat = date.toISOString().split('T')[0];

        this.setState({
            startDate: date
        })
        // onChange("start_date", date)

        onChange("expiry_date", date.toLocaleDateString("sv-SE"))


    }

    selectDate = (currentClient) => {
        // return new Date()
    }


    render() {
        // Get components props
        const {
            isOpen,
            currentAction,
            currentClient,
            currentClientValidation,
            isFormValid,
            onChange,
            onSubmit,
            onCancel
        } = this.props;

        let class_name = "modal_add_client_wrapper ";
        class_name += isOpen ? "modal_add_client_wrapper_open" : "";

        // Try to get preview image
        const preview = currentAction === "CREATE" ? (currentClient.image ? URL.createObjectURL(currentClient.image) : null) : (currentClient.image instanceof File) ? URL.createObjectURL(currentClient.image) : currentClient.image;


        const t = new Date(currentClient.expiry_date)
        return <Translation>
            {t => {
                return (
                    <div className={class_name} onClick={(e) => {
                        if (e.target.className === class_name) {
                            onCancel();
                        }
                    }}>
                        <div className="NE_modal">
                            <div className="modal_header">
                                <p className="modal_title">{currentAction === "CREATE" ? t("admin.clients-list.statics.client-modal.create_title") : t("admin.clients-list.statics.client-modal.edit_title")}</p>
                                <p className="modal_close" onClick={() => {
                                    onCancel()
                                }}>X</p>
                            </div>

                            <div className="modal_body">
                                <form action="#" method="post" encType="multipart/form-data">
                                    <div className="form-field">
                                        <div className="add_picture">
                                            <img src={preview ?? add_picture} alt="Client" onClick={() => {
                                                this.imageUploadInput.current.click();
                                            }}/>
                                            <p>{t("admin.clients-list.statics.client-modal.fields.image_label")}</p>
                                            {/*<input ref={this.imageUploadInput} type="file" onChange={(e) => {this.changeFieldValue("image",e.target.files[0])}} accept=".png,.jpeg,.jpg" hidden />*/}
                                            <input ref={this.imageUploadInput} type="file" onChange={(e) => {
                                                onChange("image", e.target.files[0])
                                            }} accept=".png,.jpeg,.jpg" hidden/>
                                        </div>
                                        {currentClientValidation.image.errors && currentClientValidation.image.errors.map(er => {
                                            return (er !== "") && <p className="avatar_error" key={er}>{er}</p>;
                                        })}
                                    </div>

                                    <div className="form-field">
                                        <NormalInput value={currentClient.full_name} onChange={(e) => {
                                            onChange("full_name", e.target.value)
                                        }} label={t("admin.clients-list.statics.client-modal.fields.full_name_label")}
                                                     isValid={currentClientValidation.full_name.is_valid}
                                                     message={currentClientValidation.full_name.error} type="text"
                                                     id="full_name"
                                                     placeholder={t("admin.clients-list.statics.client-modal.fields.full_name_placeholder")}/>
                                    </div>

                                    <div className="form-field">
                                        <NormalInput value={currentClient.type} onChange={(e) => {
                                            onChange("type", e.target.value)
                                        }} label={t("admin.clients-list.statics.client-modal.fields.type_label")}
                                                     isValid={currentClientValidation.type.is_valid}
                                                     message={currentClientValidation.type.error} type="text"
                                                     id="type_desc"
                                                     placeholder={t("admin.clients-list.statics.client-modal.fields.type_placeholder")}/>
                                    </div>

                                    <div className="form-field">
                                        <NormalInput value={currentClient.phone} onChange={(e) => {
                                            onChange("phone", e.target.value)
                                        }} label={t("admin.clients-list.statics.client-modal.fields.phone_label")}
                                                     isValid={currentClientValidation.phone.is_valid}
                                                     message={currentClientValidation.phone.error} type="text"
                                                     id="phone"
                                                     placeholder={t("admin.clients-list.statics.client-modal.fields.phone_placeholder")}/>
                                    </div>

                                    <div className="form-field">
                                        <NormalInput value={currentClient.email} onChange={(e) => {
                                            onChange("email", e.target.value)
                                        }} label={t("admin.clients-list.statics.client-modal.fields.email_label")}
                                                     isValid={currentClientValidation.email.is_valid}
                                                     message={currentClientValidation.email.error} type="email"
                                                     id="email"
                                                     placeholder={t("admin.clients-list.statics.client-modal.fields.email_placeholder")}/>
                                    </div>

                                    <div className="form-field">
                                        <NormalInput value={currentClient.address} onChange={(e) => {
                                            onChange("address", e.target.value)
                                        }} label={t("admin.clients-list.statics.client-modal.fields.address_label")}
                                                     isValid={currentClientValidation.address.is_valid}
                                                     message={currentClientValidation.address.error} type="text"
                                                     id="address"
                                                     placeholder={t("admin.clients-list.statics.client-modal.fields.address_placeholder")}/>
                                    </div>

                                    {/*<div className="form-field">*/}
                                    {/*    <NormalInput value={currentClient.expiration_date} onChange={(e) => {onChange("expiration_date",e.target.value)}} label={t("admin.clients-list.statics.client-modal.fields.expiration_date_label")} isValid={currentClientValidation.expiration_date.is_valid} message={currentClientValidation.expiration_date.error} type="text" id="exp_date" placeholder={t("admin.clients-list.statics.client-modal.fields.expiration_date_placeholder")} />*/}
                                    {/*</div>*/}

                                    <div className="form-field">
                                        <DatePicker
                                            customInput={<NormalInput value={currentClient.expiry_date}
                                                                      label={t("admin.clients-list.statics.client-modal.fields.expiration_date_label")}
                                                                      isValid={currentClientValidation.expiry_date.is_valid}
                                                                      message={currentClientValidation.expiry_date.error}/>}
                                            selected={this.state.startDate}
                                            // onChange={(date) => {onChange("expiration_date",date)}}
                                            onChange={(date) => {
                                                this.handleDateChange(date)
                                            }}
                                            placeholderText={t("admin.clients-list.statics.client-modal.fields.expiration_date_placeholder")}
                                            dateFormat="yyyy/MM/dd"
                                        />
                                    </div>
                                </form>
                            </div>

                            <div className="modal_footer">
                                <div className="actions">
                                    <div className="action">
                                        <NormalButton value={t("admin.clients-list.statics.client-modal.cancel_button")}
                                                      onClick={() => {
                                                          onCancel()
                                                      }}/>
                                    </div>

                                    <div className="action">
                                        <NormalButton button="dark"
                                                      value={currentAction === "CREATE" ? t("admin.clients-list.statics.client-modal.create_button") : t("admin.clients-list.statics.client-modal.edit_button")}
                                                      disabled={!isFormValid} onClick={() => {
                                            if (isFormValid) {
                                                onSubmit();
                                            }
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Translation>
    }
}

export default ClientModal;
