import { all, fork, put, takeLatest, delay } from "redux-saga/effects";
import {
  LOAD_ALL_COURSES,
  SUBSCRIBE_TRAINING_SESSION
} from "./action-definitions";
import {
  loadAllCoursesSuccess,
  loadAllCoursesError,
  loadLastCommentsSuccess,
  subscribeTrainingSessionSuccess,
  subscribeTrainingSessionError
} from "./action-creators";
import {
  loadAllCourses,
  loadLastComments,
  subscribeTrainingSession
} from "../../../services/participant.service";

/* Define the actions watchers */
export function* watchLoadAllCourses() {
  yield takeLatest(LOAD_ALL_COURSES, loadAllCoursesWorker);
}
export function* watchSubscribeTrainingSessionWorker() {
  yield takeLatest(SUBSCRIBE_TRAINING_SESSION, subscribeTrainingSessionWorker);
}

/* Define the actions workers */
function* loadAllCoursesWorker({ payload }) {
  // Get admin data from payload
  //
  // Attempt to call backend to load clients list
  try {
    const call_result = yield loadAllCourses();
    const lastCommentResult = yield loadLastComments();
    //
    // Dispatch success
    yield put(loadLastCommentsSuccess(lastCommentResult.data));
    yield put(loadAllCoursesSuccess(call_result.data));
  } catch (error) {
    yield put(loadAllCoursesError(["Cannot load clients list."]));
  }
}

/* Define the actions workers */
function* subscribeTrainingSessionWorker({ payload }) {
  // Get admin data from payload
  //
  // Attempt to call backend to load clients list
  try {
    const call_result = yield subscribeTrainingSession(payload.code);

    //

    yield put(subscribeTrainingSessionSuccess(call_result.data));
    payload.callback();
  } catch (error) {
    yield put(subscribeTrainingSessionError("Invalid TrainingSession Code"));
  }
}

// Export the combined sagas
export default function* allSagas() {
  yield all([
    fork(watchLoadAllCourses),
    fork(watchSubscribeTrainingSessionWorker)
  ]);
}
