import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import './index.css'
import icon from './assets/icon-question.svg';
import style from "../../../configure-course/components/quiz-component/style.module.scss";
import { uid } from "uid";
import {
  editItemCourse,
  editModule,
  restElement
} from "../../../../../redux/moderator/configure-course/actions-creators";
import { useDispatch, useSelector } from "react-redux";
import { deleteEvaluationOption, editEvaluation, deleteEvaluation } from "../../../../../redux/moderator/edit-course/actions";
import DeleteElementsModal from "../../../configure-course/components/delete-elements-modal";
import Textarea from "../../../../../components/Textarea";
import Switch from "../../../../../components/Switch";
import PublishedElement from "../PublishedElement/PublishedElement";
import useMoveElementCourse from "../../../../../../utils/moveElementCourse";
import arrowUp from "../DocumentsCard/assets/arrow-up.svg";
import arrowDown from "../DocumentsCard/assets/arrow-down.svg";
// import DeleteElementsModal from "../delete-elements-modal";


const ItemContext = createContext({
  items: [],
  _id: null,
  question: "",
  showButtons: true,
  stateQuestion: 0,
  stateJustification: 0,
  deletedItems: [],
  justification: "",
  addItem: () => {
  },
  deleteItem: () => {
  },
  editItem: () => {
  },
  editQuestion: () => {
  },
  changeQuestionOption: () => {
  },
  editJustification: () => {
  },
});

const initialState = [
  // {
  //     text: "option",
  //     question_option:true,
  //     name_group:1,
  //     _id: uid()
  // }
];


const ItemProvider = (props) => {

  const [items, setItems] = useState(initialState);
  const [question, setQuestion] = useState("");
  const [showButtons, setShowButtons] = useState(true);
  const [stateQuestion, setStateQuestion] = useState(0);
  const [stateJustification, setStateJustification] = useState(0);
  const [justification, setJustification] = useState("");

  const addItem = (item) => {

    setItems((items) => [...items, { ...item, ...(items.length <= 0 ? { question_option: true, is_correct: true } : {}), state_option: 0 }]);


    // const list = [...items, { ...item, ...(items.length <= 0 ? { question_option: true, is_correct: true } : {}), state_option: 0 }]
    //
    // console.log("-addItem---item--->>",list)

    // setItems((prv)=>[...list]);
    // listEdit(list, question, stateQuestion);

  };
  const deleteItem = (id) => {
    const list = items.filter((item) => item._id !== id)
    setItems(list);
    listEdit(list, question, stateQuestion);

  }

  const editJustification = (text) => {
    setJustification(text);
    const value = stateJustification === 0 ? 0 : 1;
    setStateJustification(value);
    listEditJustification(items, text, value);
  };

  const editItem = (id, text) => {
    const list = items.map((elem) => {
      if (elem._id === id) return {
        ...elem,
        content: text,
        state_option: elem.state_option === 0 ? elem.state_option : 1
      };
      return elem;
    });
    setItems(list);
    listEdit(list, question, stateQuestion);
  };

  const editQuestion = (text) => {

    setQuestion(text);
    const value = stateQuestion === 0 ? 0 : 1;
    setStateQuestion(value);
    listEdit(items, text, value);

  };
  const changeQuestionOption = (id) => {
    const list = items.map((elem) => {
      if (elem.is_correct) return {
        ...elem,
        is_correct: false,
        state_option: elem.state_option === 0 ? elem.state_option : 1
      }
      if (elem._id === id) return {
        ...elem,
        is_correct: true,
        state_option: elem.state_option === 0 ? elem.state_option : 1
      };
      return { ...elem, is_correct: false };
    });


    setItems(list);
    listEdit(list, question, stateQuestion);
  };

  const objectsEqual = (o1, o2) =>
    Object.keys(o1).length === Object.keys(o2).length
    && Object.keys(o1).every(p => o1[p] === o2[p]);

  const listEdit = (items, question, stateQuestion) => {

    const list = props.ListQuiz.map((elem, index) => {

      if (elem._id === props.quiz._id) {
        return { ...elem, items, question, stateQuestion }
      }
      return elem;
    });

    props.setList(list);

  };

  const listEditJustification = (items, justification, stateJustification) => {

    const list = props.ListQuiz.map((elem, index) => {

      if (elem._id === props.quiz._id) {
        return { ...elem, items, justification, stateJustification }
      }
      return elem;
    });

    props.setList(list);

  };


  useEffect(() => {
    if (items.length === 0 && props.quiz.options) {
      setItems(props.quiz.options?.map(elm => {
        return {
          ...elm,
          state_option: -1,
          _id: uid()
        }
      }));
    }
    if (!question && props.quiz.title) {
      setQuestion(props.quiz.title)
      setStateQuestion(-1)
    }
    if (!justification && props.quiz?.justification) {
      setJustification(props.quiz.justification)
      setStateJustification(-1)
    }

  }, [props.quiz]);

  useEffect(() => {
    setShowButtons(props.showButtons)
  }, [props.showButtons]);


  const value = {
    items,
    question,
    stateQuestion,
    showButtons,
    justification,
    stateJustification,
    addItem,
    deleteItem,
    editItem,
    editQuestion,
    changeQuestionOption,
    editJustification,
  };

  return (
    <ItemContext.Provider value={value}>{props.children}</ItemContext.Provider>
  );
};

function ItemQuestionComponent({ elem, name_group, deleteQuestionOption }) {

  const [isEdit, setIsEdit] = useState(false);
  const { deleteItem, editItem, changeQuestionOption, showButtons } = useContext(ItemContext);

  return <li className={`question_option  ${elem.is_correct ? 'question_option_selected' : ''} `}>
    {/*<pre>*/}
    {/*     {JSON.stringify(elem)}*/}
    {/*</pre> <br/>*/}
    <div className={`NE_radio_button ${style.NE_radio_button}`}>
      <input type="radio" name={name_group} defaultChecked={elem.is_correct} id={elem._id}
        onClick={() => showButtons ? changeQuestionOption(elem._id) : null} />
      {isEdit && showButtons ?
        <label htmlFor={elem._id}>
          <div className={style.block_}>
            <input className={style.input} onChange={(e) => editItem(elem._id, e.target.value)}
              value={elem.content} />
          </div>
        </label>
        :
        <label htmlFor={showButtons ? elem._id : "-1"}>
          {elem.content}
        </label>
      }

    </div>
    {showButtons && <>
      <span className={`question_option_action ${isEdit ? style.btn_save : style.btn_edit}`}
        onClick={() => setIsEdit(!isEdit)} />
      <span className={`${style.question_option_action_trash}`} onClick={() => {
        deleteItem(elem._id);
        deleteQuestionOption(elem)
      }} />
    </>}
    {elem.question_option &&
      <span className={`${style.question_option_action_valid}`} />
    }
  </li>;
}

function ListQuestionComponent({ name_group, deleteQuestionOption }) {
  const [list, setList] = useState([]);
  const { items, addItem, showButtons } = useContext(ItemContext);


  useEffect(() => {

    if (items.length === 0) {
      addElement();
      addElement();
      addElement();
      addElement();
    }

  }, []);


  const addElement = () => {
    addItem({
      content: "option",
      question_option: false,
      name_group: name_group,
      _id: uid()
    });
  }
  const onDelete = (id) => (e) => {
    setList(list.filter(elm => elm._id !== id))
  }
  return <ul className="question_options">
    {items.map((elem, index) => <ItemQuestionComponent deleteQuestionOption={deleteQuestionOption}
      name_group={name_group} elem={elem} key={elem.id}
      onDelete={onDelete} />)}

    {showButtons && <li className="add_question_option" onClick={addElement}>
      Add Option
        </li>}
  </ul>;
}


function QuizFormComponent({ element, deleteElement, current_course, deleteQuestionOption }) {
  const { editQuestion, question, showButtons, items, stateQuestion , justification , editJustification } = useContext(ItemContext);

  return <div className={`form-field ${style.form_field}`}>
    <div className="question">
      <div className="NE_normal_input">
        <div className={`${style.block_elemnt}`}>
          <label htmlFor="question1">Question</label>
          {!current_course.publish && <span className={`${style.question_option_action_trash}`}
            onClick={() => deleteElement(element)} />}
        </div>

        <input type="text" id="question1"
          value={question}
          onChange={(e) => {
            editQuestion(e.target.value)
          }
          }
          placeholder="Write your question"
          disabled={!showButtons}
        />
      </div>
      <ListQuestionComponent deleteQuestionOption={deleteQuestionOption} name_group={element._id} />
      <div className="NE_normal_input">
        <Textarea
            value={justification}
            onChange={(e) => editJustification(e.target.value)}
            placeholder="Justificatif dela réponse"
        />

      </div>
    </div>
  </div>
}

function QuizFormProvider({
  element,
  setList,
  ListQuiz,
  showButtons,
  deleteElement,
  current_course,
  deleteQuestionOption
}) {

  return <ItemProvider showButtons={showButtons} quiz={element} ListQuiz={ListQuiz} setList={setList}>
    <QuizFormComponent deleteQuestionOption={deleteQuestionOption} current_course={current_course}
      deleteElement={deleteElement} element={element} />
  </ItemProvider>;
}

const EvaluationCard = ({ element , isOpen = true , editMode  }) => {

  const [list_delete_question_option, setList_delete_question_option] = useState([]);
  const [list_delete_question, setList_delete_question] = useState([]);
  const [is_open, setIsOpen] = useState(isOpen);
  const [is_open_delete, setIsOpenDelete] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const [List, setList] = useState([]);
  const { items } = useContext(ItemContext);
  const dispatch = useDispatch();
  const { current_course } = useSelector(state => state.moderator_configure_course);
  const { current_course: current_course_edit } = useSelector((state) => state.editCourse);
  const [edit_title , setEditTitle] = useState(false);
  const _title = useRef()
  const [moveElement , indexItem , length] = useMoveElementCourse({element,current_course})

  const addElement = () => {
    setList([...List, { _id: uid() }])
  };

  useEffect(() => {
    if (_title.current) {
      _title.current.value = element?.title;
    }
  }, [_title,edit_title]);

  useEffect(() => {


    if (element?.questions?.length > 0) {
      const list = element.questions.map((elem, index) => {
        return {
          ...elem,
          _id: uid()
        }
      })
      setList(list);
    }

  }, [current_course_edit]);


  const toggleCollapse = () => {
    setIsOpen(!is_open);
  }


  let class_name = "NE_collapsable_card_with_icon quiz_creation_card ";
  class_name += is_open ? "NE_collapsable_card_with_icon_open" : "";

  const deleteEvaluationButton = () => {
    setIsOpenDelete(false);

    dispatch(
      deleteEvaluation(element.id)
    );
  }

  const isValid = () => {

    if (List.length <= 0) return false;

    return List.reduce((previousValue, currentValue) => {
      let found = false;
      if (currentValue?.items?.length > 0) {
        found = !!currentValue.items.find(element => element.question_option === true);
      }


      return currentValue.question !== "" && currentValue.items?.length > 0 && found && previousValue;
    }, true);

  }

  const deleteElement = (elem) => {
    // dispatch(deleteEvaluationOption({...elem,quiz_id:element.id}))
    setList_delete_question(prv => [...prv, elem])
    setList(List.filter((elm) => elm._id !== elem._id || elm.id !== elem.id))
  };

  const deleteElementQuestionOption = (elem) => {
    dispatch(deleteEvaluationOption({ ...elem, quiz_id: element.id }))
    if (elem.id) {
      setList_delete_question_option(prevState => [...prevState, { ...elem, quiz_id: element.id }])
    }
  };

  const save = () => {
    const list_add_question = [];
    const list_add_question_option = [];
    const list_edit_question = [];
    const list_edit_question_option = [];



    List.map((elem, index) => {
      if (elem.stateQuestion === 0 || elem.stateJustification === 0) {
        list_add_question.push({
          ...elem,
          title: elem.question,
          options: elem.items?.map((el, i) => ({ ...el, element_order: i, is_correct: el.is_correct ? 1 : 0 })),
          element_order: index,
          quiz_id: element.id,
          justification: elem.justification ?? "",
        })
        return elem;
      } else if (elem.stateQuestion === 1 || elem.stateJustification === 1) {
        list_edit_question.push({
          title: elem.question ?? elem.title,
          id: elem.id,
          quiz_id: element.id,
          element_order: index,
          justification: elem.justification ?? "",
        });
      }
      elem.items?.map((item, index) => {
        if (item.state_option === 0) list_add_question_option.push({
          content: item.content,
          is_correct: item.is_correct ? 1 : 0,
          question_id: elem.id,
          element_order: index,
          quiz_id: element.id
        })
        else if (item.state_option === 1) list_edit_question_option.push({
          content: item.content,
          is_correct: item.is_correct ? 1 : 0,
          id: item.id,
          question_id: elem.id,
          element_order: index,
          quiz_id: element.id
        })
        return item;
      })

      return elem;
    })


    dispatch(editEvaluation(
      list_add_question,
      list_add_question_option,
      list_edit_question,
      list_edit_question_option,
      list_delete_question_option,
      list_delete_question
    ));
    setList_delete_question_option([]);
    setList_delete_question([]);
  }

  const editTitle = () => {
    if (edit_title){
      setEditTitle(false);

      const _item = {
        id:element.id ,
        title:_title.current.value,
        description:element.description,
        element_order:element.element_order,
        published:element.published,
        type:element.type
      }

      dispatch(editItemCourse(_item,"evaluation"));
      return;
    }
    setEditTitle(true)
  }

  return <>
    <DeleteElementsModal isOpen={is_open_delete} onCancel={() => setIsOpenDelete(false)} onDelete={deleteEvaluationButton} />
    <div className={class_name}>

      <div className="header">
        <div className="title">
           <span className={"d-flex"} >

             {<img src={arrowUp} className={`arrow`} alt={""} onClick={() => indexItem !== 0 ?  moveElement("down") : null}/>}
             {<img src={arrowDown} className={`arrow `} alt={""} onClick={() => length-1 !== indexItem ? moveElement("up") : null}/>}
           </span>
          <img src={icon} alt="Container icon" />
          {edit_title ?
              <div className="form-field">
                <div className="NE_normal_input">
                  <input
                      ref={_title}
                      onChange={(e)=>{
                        _title.current.value  = e.target.value;
                      }}
                  />
                </div>
              </div>
              : <h2>{element?.title}</h2>
          }
        </div>


        {!current_course.publish && <div className="delete_element_content">
          <PublishedElement element={element} type={"evaluation"}/>

          <span className={`${ !edit_title ? 'btn_edit' : 'btn_save'}`} onClick={editTitle} />
          {editMode && <>

            <span className="btn_delete_element" onClick={() => {
              setIsOpenDelete(true)
            }}> </span>
          </>
          }



          <span className="btn_collapse" onClick={() => {
            toggleCollapse()
          }} />
        </div>}
      </div>
      <div className="body">
        <form>
          {
            List.map((elem, index) => <QuizFormProvider deleteQuestionOption={deleteElementQuestionOption}
              current_course={current_course}
              deleteElement={deleteElement}
              showButtons={showButtons}
              ListQuiz={List}
              setList={setList}
              element={elem}
              key={elem._id} />)
          }
          {showButtons && <div className="add_question" onClick={addElement} />}


          {showButtons && List.length > 0 &&

            <div className="actions mt-2">
              <div className="action">
                <button className="NE_button_large NE_button_outline"
                  type="reset"
                  onClick={() => {
                    dispatch(restElement(element))
                  }}
                >Cancel
                                </button>
              </div>

              <div className="action">
                {/*disabled={!isValid()}*/}
                <button className="NE_button_large NE_button_dark" type="button" onClick={save}>Save
                                </button>
              </div>
            </div>

          }
        </form>
      </div>
    </div>
  </>

}
export default EvaluationCard;
