import React,{Component} from "react";
import './index.css';
import logo from '../../assets/logo.png'
import axios from "axios";
import Header from "../../../components/Header";
import CoursePublishOverview from "../../../components/CoursePublishOverview";
import NormalInput from "../../../components/NormalInput";
import Textarea from "../../../components/Textarea";
import {
    loadAllCategories,
    loadAllCourses
} from "../../../redux/moderator/courses-list/action-creators";
import {connect} from "react-redux";

class ModeratorAddElement extends Component{
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: ''
        }

    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = () => {

    }

    componentDidMount() {
        const id = this.props.match.params.id ;
        this.props.loadAllCourses();
    }

    render() {
        const id = Number(this.props.match.params.id);
        const {courses_list} = this.props.moderator_courses_list;
        const current_course = courses_list?.filter(item => item.course.id === id)[0]
        const toggle_bar_button = () => {
            if (this.state.title && this.state.description){
                return (
                    <div className="NE_toggle_bar_button NE_toggle_bar_button_open">
                        <div className="toggle_button">
                            <img src="../../../globals/ToggleBarButton/assets/add.svg" alt="Toggle button" />
                        </div>
                        <div className="actions_bar">
                            <div className="action">
                                <img src="../../../globals/ToggleBarButton/assets/module.svg" alt="Bar action" />
                                <p>Notion</p>
                            </div>
                            <div className="action">
                                <img src="../../../globals/ToggleBarButton/assets/documents.svg" alt="Bar action" />
                                <p>Documents</p>
                            </div>
                            <div className="action">
                                <img src="../../../globals/ToggleBarButton/assets/trophy.svg" alt="Bar action" />
                                <p>Business game</p>
                            </div>
                            <div className="action">
                                <img src="../../../globals/ToggleBarButton/assets/evaluation.svg" alt="Bar action" />
                                <p>Evaluation</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        return (
            <div className="moderator_add_element_page">
                <div className="page_header">
                    <Header user='moderator'/>
                </div>
                <div className="page_body">
                    <div className="course_publish_overview_wrapper">
                        <CoursePublishOverview courseData={current_course ?  current_course.course : {}}/>
                    </div>
                    <div className="create_course_form_wrapper">
                        <div className="form-field">
                            <NormalInput label="Title" placeholder="Element title"/>
                        </div>
                        <div className="form-field">
                            <Textarea label="Description" placeholder="Description about the course"/>
                        </div>
                        <div className="form-actions">
                            <div className="form-action">
                                <button className="NE_button_large NE_button_outline" type="reset">Cancel</button>
                            </div>
                            <div className="form-action">
                                <button onClick={this.handleSubmit} className="NE_button_large NE_button_dark">Create Element</button>
                            </div>
                            {toggle_bar_button}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {moderator_courses_list} = state;
    return {moderator_courses_list};
};

const mapActionsToProps = {
    loadAllCourses,
    loadAllCategories,
};

export default connect(mapStateToProps, mapActionsToProps)(ModeratorAddElement);






